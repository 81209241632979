import React from 'react';
import { OverlayView } from '@react-google-maps/api';
import { StoreMarkerImage } from './StoreMarkerImage';
import { MarkerLabel } from '@Features/homepage/homepage.types';
import useHover from '@Hooks/useHover';

interface Props {
  marker: MarkerLabel;
  onClick: () => void;
}

export const StoreMarker: React.FC<Props> = props => {
  const { marker, onClick } = props;

  const [ref, isHovered] = useHover<HTMLDivElement>();

  return (
    <OverlayView
      position={marker.position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div className="homepage-marker" ref={ref}>
        <StoreMarkerImage {...marker} onClick={onClick} />
        {isHovered && <p className="homepage-marker-label">{marker.name}</p>}
      </div>
    </OverlayView>
  );
};

import { TimePicker, TimeRangePickerProps } from 'antd';

const { RangePicker } = TimePicker;

export default function TimeRangeSelector(props: TimeRangePickerProps) {
  return (
    <RangePicker
      format="HH:mm"
      allowClear={false}
      style={{ width: 150 }}
      {...props}
    />
  );
}

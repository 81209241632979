import { Button, Card, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { PlusCircleOutlined } from '@ant-design/icons';
import CardGridWrapper from '@Components/CardGridWrapper';
import React from 'react';
import { getAllGateway } from '@Store/Gateway/action';
import SortByFilter from './Components/SortByFilter';
import GatewayCard from '@Cards/GatewayCard';
import useDataDisplay from '@Hooks/useDataDisplay';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import DataDisplayTable from '@Components/DataDisplayTable';
import useGatewaysColumns from './useGatewaysColumns';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import { FilterOption } from '@Types/Pagination';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';

const Gateways = () => {
  const [currentDisplay, toggleDisplay] = useDataDisplay('gateways');
  const allGateway = useAppSelector(s => s.Gateaways.allGateway);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const filterOptions: FilterOption[] = [
    { field: 'Id', label: 'ID', type: FilterType.NUMBER },
    { field: 'ExternalId', label: t('externalId'), type: FilterType.TEXT },
    {
      field: 'Store.BrandId',
      label: t('brand') + ' ID',
      type: FilterType.NUMBER,
    },
    { field: 'Store.Brand.Name', label: t('brand'), type: FilterType.TEXT },
    { field: 'StoreId', label: t('store') + ' ID', type: FilterType.NUMBER },
    { field: 'Store.Name', label: t('store'), type: FilterType.TEXT },
  ];

  const pageConfigResult = useUserPageConfig(
    'gateways',
    {
      orderBy: 'asc',
      sortKey: 'Id',
    },
    { fields: filterOptions.map(o => o.field) }
  );

  const { pagination, refetch } = usePaginatedQuery({
    status: allGateway.status,
    filterOptions,
    pageConfigResult,
    queryFunction: p => dispatch(getAllGateway({ filters: p })),
  });

  const gatewaysTableColumns = useGatewaysColumns({
    gateways: allGateway,
    getGateways: refetch,
  });

  useSetHeaderTitle('Gateway');

  return (
    <Card>
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter
            onChange={pagination.onSortByChange}
            defaultValue={pagination.SortBy}
          />
          <PaginationOrderByFilter
            onChange={pagination.onOrderByChange}
            defaultValue={pagination.OrderBy}
          />
          <PaginationFilter
            filterOptions={filterOptions}
            pagination={pagination}
          />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          <Link to="new-gateway">
            <Button type="primary" icon={<PlusCircleOutlined />}>
              {t('addGateway')}
            </Button>
          </Link>
        </div>
        <div className="card-grid-container">
          <CardGridWrapper
            status={allGateway.status}
            noData={allGateway.data?.Data?.length === 0}
            cards={
              currentDisplay === 'card' ? (
                <React.Fragment>
                  {allGateway.data?.Data?.map(gateway => (
                    <GatewayCard gateway={gateway} getGateways={refetch} />
                  ))}
                </React.Fragment>
              ) : (
                <DataDisplayTable
                  columns={gatewaysTableColumns}
                  dataSource={allGateway.data?.Data}
                />
              )
            }
          />
        </div>
        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pagination.PageIndex}
          onChange={pagination.onPageIndexChange}
          pageSize={allGateway.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={allGateway.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
};

export default Gateways;

import { Button, Card, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { getAllVersions } from '@Store/Version/action';
import CardGridWrapper from '@Components/CardGridWrapper';
import VersionCard from '../../Cards/VersionCard';
import SortByFilter from './Components/SortByFilter';
import VersionFilesTable from './Components/VersionFilesTable';
import useVersionsColumns from './useVersionsColumns';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import DataDisplayTable from '@Components/DataDisplayTable';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import useDataDisplay from '@Hooks/useDataDisplay';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { FilterOption } from '@Types/Pagination';
import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';
import { getAllHardware } from '@Store/Hardware/action';
import { noPaginationParams } from '@Hooks/usePagination.ts/constants';
import Status from '@Enums/Status';

const Versions = () => {
  const [currentDisplay, toggleDisplay] = useDataDisplay('versions');
  const allVersions = useAppSelector(s => s.Version.allVersions);
  const allHardware = useAppSelector(s => s.Hardware.allHardware);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllHardware(noPaginationParams));
  }, []);

  const filterOptions: FilterOption[] = [
    { field: 'Id', label: 'ID', type: FilterType.NUMBER },
    { field: 'Name', label: t('name'), type: FilterType.TEXT },
    {
      field: 'HardwareId',
      label: t('hardware'),
      type: FilterType.NUMBER_ARRAY_SELECT,
      loading: allHardware?.status === Status.pending,
      options: allHardware?.data?.Data?.map(u => ({
        label: u.Name,
        value: u.Id,
      })),
    },
  ];

  const pageConfigResult = useUserPageConfig(
    'versions',
    {
      orderBy: 'asc',
      sortKey: 'Name',
    },
    { fields: filterOptions.map(o => o.field) }
  );

  const { pagination, refetch } = usePaginatedQuery({
    status: allVersions.status,
    filterOptions,
    pageConfigResult,
    queryFunction: p =>
      dispatch(
        getAllVersions({
          filters: p,
        })
      ),
  });

  const versionsTableColumns = useVersionsColumns({
    versions: allVersions,
    getVersions: refetch,
  });

  useSetHeaderTitle(t('allVersions'));

  return (
    <Card>
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter
            onChange={pagination.onSortByChange}
            defaultValue={pagination.SortBy}
          />
          <PaginationOrderByFilter
            onChange={pagination.onOrderByChange}
            defaultValue={pagination.OrderBy}
          />
          <PaginationFilter
            filterOptions={filterOptions}
            pagination={pagination}
          />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          <Link to="new-version">
            <Button type="primary" icon={<PlusCircleOutlined />}>
              {t('addVersion')}
            </Button>
          </Link>
        </div>
        <CardGridWrapper
          status={allVersions.status}
          noData={allVersions.data?.Data?.length === 0}
          cards={
            currentDisplay === 'card' ? (
              <>
                {allVersions.data?.Data?.map(vers => (
                  <VersionCard
                    key={vers.Id}
                    version={vers}
                    getVersions={refetch}
                  />
                ))}
              </>
            ) : (
              <DataDisplayTable
                columns={versionsTableColumns}
                dataSource={allVersions.data?.Data}
              />
            )
          }
        />
        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pagination.PageIndex}
          onChange={pagination.onPageIndexChange}
          pageSize={allVersions.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={allVersions.data?.TotalNumberOfRecords}
        />
      </div>

      <VersionFilesTable />
    </Card>
  );
};

export default Versions;

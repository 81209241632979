import React from 'react';
import { getActiveBoardCount } from '@Utils/index';

interface Props {
  AtLeast3DaysPassiveBoardCount: number;
  BoardCount: number;
  NotInstalledBoardCount: number;
  PassiveBoardCount: number;
  onClick: () => void;
}

export const StoreMarkerImage: React.FC<Props> = props => {
  const {
    AtLeast3DaysPassiveBoardCount,
    NotInstalledBoardCount,
    PassiveBoardCount,
    ...restProps
  } = props;

  const offBoards = PassiveBoardCount;
  const notInstalledBoardCount = NotInstalledBoardCount;
  const atLeast3DaysPassiveBoardCount = AtLeast3DaysPassiveBoardCount;
  const onBoards = getActiveBoardCount(props);

  const statusCount = [
    offBoards,
    notInstalledBoardCount,
    atLeast3DaysPassiveBoardCount,
    onBoards,
  ].filter(Boolean).length;

  const Image = ({ src }: { src: string }) => (
    <img
      {...restProps}
      crossOrigin=""
      alt="Status Marker"
      src={src}
      style={{ width: 24, height: 24 }}
    />
  );

  if (statusCount === 1) {
    if (offBoards) return <Image src="clusters/off.png" />;
    if (notInstalledBoardCount)
      return <Image src="clusters/notInstalled.png" />;
    if (atLeast3DaysPassiveBoardCount)
      return <Image src="clusters/longPassive.png" />;
    if (onBoards) return <Image src="clusters/on.png" />;
  } else if (statusCount === 2) {
    if (offBoards && notInstalledBoardCount)
      return <Image src="clusters/off-notInstalled.png" />;
    if (offBoards && atLeast3DaysPassiveBoardCount)
      return <Image src="clusters/off-longPassive.png" />;
    if (offBoards && onBoards) return <Image src="clusters/off-on.png" />;
    if (notInstalledBoardCount && atLeast3DaysPassiveBoardCount)
      return <Image src="clusters/notInstalled-longPassive.png" />;
    if (notInstalledBoardCount && onBoards)
      return <Image src="clusters/notInstalled-on.png" />;
    if (atLeast3DaysPassiveBoardCount && onBoards)
      return <Image src="clusters/longPassive-on.png" />;
  } else if (statusCount === 3) {
    if (offBoards && notInstalledBoardCount && atLeast3DaysPassiveBoardCount)
      return <Image src="clusters/off-notInstalled-longPassive.png" />;
    if (offBoards && notInstalledBoardCount && onBoards)
      return <Image src="clusters/off-notInstalled-on.png" />;
    if (offBoards && atLeast3DaysPassiveBoardCount && onBoards)
      return <Image src="clusters/off-longPassive-on.png" />;
    if (notInstalledBoardCount && atLeast3DaysPassiveBoardCount && onBoards)
      return <Image src="clusters/notInstalled-longPassive-on.png" />;
  } else if (statusCount === 4) {
    return <Image src="clusters/off-notInstalled-longPassive-on.png" />;
  }

  return null;
};

import {
  GoogleMap,
  Libraries,
  Marker,
  MarkerClusterer,
  StandaloneSearchBox,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getStoresGeoCoords } from '@Store/Store/action';
import { Divider, Input, Skeleton } from 'antd';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { MarkerExtended, MarkerLabel } from '@Features/homepage/homepage.types';
import { StoreMarker } from '../StoreMarker';
import { Cluster } from '@react-google-maps/marker-clusterer';
import { ClusterInfo } from '../ClusterInfo';
import { MarkerInfo } from '../MarkerInfo';
import { useHomepageContext } from '@Features/homepage/hooks';
import { checkHomepageTags } from '@Utils/index';
import { createClustererStyles } from '@Features/homepage/homepage.utils';

const libraries: Libraries = ['places', 'marker'];

const containerStyle: CSSProperties = {
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
};

const center = {
  lat: 41.058325023151575,
  lng: 28.9777564692437,
};

interface HomepageMapProps {}

export function HomepageMap(props: HomepageMapProps) {
  const [map, setMap] = useState<google.maps.Map>();
  const [zoom, setZoom] = useState<number>(14);
  const [markerLabels, setMarkerLabels] = useState<MarkerLabel[]>([]);
  const [selectedCluster, setSelectedCluster] = useState<Cluster>();
  const [selectedMarker, setSelectedMarker] = useState<MarkerLabel>();

  const { brandId, tags } = useHomepageContext();

  const searchBoxRef = useRef<google.maps.places.SearchBox>();
  const clustererRef = useRef<MarkerClusterer>(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  });

  useEffect(() => {
    dispatch(getStoresGeoCoords());
  }, []);

  const geoCoordStores = useAppSelector(s => s.Store.geoCoordStores);

  const locationMarkers = useMemo<MarkerLabel[]>(() => {
    if (!geoCoordStores.data?.Data) {
      return [];
    }

    const result = geoCoordStores.data.Data.filter(item => {
      return (
        checkHomepageTags(item, tags) &&
        (brandId ? brandId === item.BrandId : true)
      );
    }).map(item => ({
      ...item,
      key: item.StoreId.toString(),
      id: item.StoreId,
      name: `${item.BrandName} / ${item.StoreName}`,
      position: new google.maps.LatLng({
        lat: item.Latitude,
        lng: item.Longitude,
      }),
    }));

    return result;
  }, [geoCoordStores.data?.Data, brandId, tags]);

  useEffect(() => {
    clustererRef.current?.state.markerClusterer?.repaint();
  }, [locationMarkers]);

  const minimumClusterSize = zoom >= 16 ? 1000000 : 6;

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(undefined);
  }, []);

  function handleMapClick(e: google.maps.MapMouseEvent) {
    if (!e.latLng) return;
  }

  function onSearchBoxLoad(ref: google.maps.places.SearchBox) {
    searchBoxRef.current = ref;
  }

  function onPlacesChanged() {
    const places = searchBoxRef.current?.getPlaces();
    const place = places?.[0];
    const location = place?.geometry?.location;
    const lat = location?.lat();
    const lng = location?.lng();

    if (!(lat && lng)) return;

    map?.setCenter({ lat, lng });
  }

  const markerLabelsHandler = (
    clusterer: NonNullable<MarkerClusterer['state']['markerClusterer']>
  ) => {
    const markerLabelsList: MarkerLabel[] = [];

    for (const cluster of clusterer.clusters) {
      const clusterMarkers: MarkerExtended[] = cluster.getMarkers() ?? [];

      clusterMarkers.forEach(marker => {
        if (clusterMarkers.length < minimumClusterSize) {
          const locationMarker = locationMarkers.find(
            m => `${m.BrandName} / ${m.StoreName}` === marker.getLabel()
          );

          if (locationMarker) {
            markerLabelsList.push(locationMarker);
          }
        }
      });
    }

    setMarkerLabels(markerLabelsList);
  };

  function handleZoomChange() {
    setZoom(map?.getZoom() ?? 14);
    setSelectedCluster(undefined);
    setSelectedMarker(undefined);
  }

  function handleClusterSelect(cluster: Cluster) {
    setSelectedCluster(prevS => (prevS === cluster ? undefined : cluster));
  }

  function handleMarkerSelect(marker: MarkerLabel) {
    setSelectedMarker(prevS => (prevS === marker ? undefined : marker));
  }

  const zoomEnabled = !selectedCluster && !selectedMarker;

  const clusterStyles = useMemo(() => createClustererStyles('#4da9ff'), []);

  return (
    <>
      <Divider type="vertical" className="homepage-divider" />
      <div className="homepage-map-container homepage-entity-selector-container">
        {isLoaded && geoCoordStores.data?.Data ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={handleMapClick}
            onZoomChanged={handleZoomChange}
            options={{
              disableDoubleClickZoom: !zoomEnabled,
              zoomControl: zoomEnabled,
              scrollwheel: zoomEnabled,
            }}
            clickableIcons={false}
          >
            <MarkerClusterer
              ignoreHidden
              onLoad={markerLabelsHandler}
              onClusteringEnd={markerLabelsHandler}
              minimumClusterSize={minimumClusterSize}
              onClick={handleClusterSelect}
              zoomOnClick={false}
              ref={clustererRef}
              styles={clusterStyles}
            >
              {clusterer => {
                return (
                  <>
                    {locationMarkers.map(marker => (
                      <Marker
                        key={marker.id}
                        clusterer={clusterer}
                        noClustererRedraw={true}
                        position={marker.position}
                        label={marker.name}
                        opacity={0}
                      />
                    ))}
                  </>
                );
              }}
            </MarkerClusterer>

            {markerLabels.map((marker, i) => (
              <StoreMarker
                key={i}
                marker={marker}
                onClick={() =>
                  handleMarkerSelect({
                    ...marker,
                    ...locationMarkers.find(
                      m => `${m.BrandName} / ${m.StoreName}` === marker.name
                    ),
                  })
                }
              />
            ))}

            {selectedMarker && (
              <MarkerInfo
                map={map}
                locationMarker={selectedMarker}
                onOutsideClick={() => setSelectedMarker(undefined)}
              />
            )}

            {selectedCluster && (
              <ClusterInfo
                cluster={selectedCluster}
                locationMarkers={locationMarkers}
                onOutsideClick={() => setSelectedCluster(undefined)}
              />
            )}

            <StandaloneSearchBox
              onLoad={onSearchBoxLoad}
              onPlacesChanged={onPlacesChanged}
            >
              <Input
                className="location-picker-search"
                placeholder={t('search...')}
                style={{
                  width: 250,
                  height: 40,
                  position: 'absolute',
                  top: 10,
                  right: 58,
                }}
              />
            </StandaloneSearchBox>
          </GoogleMap>
        ) : (
          <Skeleton.Node
            active
            style={{ ...containerStyle, width: '100%', height: '100%' }}
            children={false}
          />
        )}
      </div>
    </>
  );
}

import Dashboard from '@Container/Dashboard/index';
import { RequireAuth } from '@HOC/RequireAuth';
import Beacons from '@Pages/Beacons';
import Data from '@Pages/Data';
import Login from '@Pages/Login';
import Users from '@Pages/Users';
import NewRole from '@Pages/Roles/Pages/NewRole';
import RoleEdit from '@Pages/Roles/Pages/RoleEdit';
import Roles from '@Pages/Roles';
import Versions from '@Pages/Versions';
import { Outlet, Route, Routes } from 'react-router-dom';
import AllBrands from '@Pages/Brands/Pages/AllBrands';
import EditBrand from '@Pages/Brands/Pages/EditBrand';
import AddBrand from '@Pages/Brands/Pages/AddBrand';
import ApiKeyList from '@Pages/Brands/Pages/ApiKeyList';
import AddApiKey from '@Pages/Brands/Pages/AddApiKey';
import AllBoards from '@Pages/Boards/Pages/AllBoards';
import AllCameras from '@Pages/Cameras/Pages/AllCameras';
import EditStore from '@Pages/Stores/Pages/EditStore';
import AddStore from '@Pages/Stores/Pages/AddStore';
import ConnectCameras from '@Pages/Stores/Pages/ConnectCameras';
import StoreProducts from '@Pages/Brands/Pages/StoreProducts';
import Outlets from './Outlets/index';
import BrandStores from '@Pages/Stores/Pages/BrandStores';
import AllStores from '@Pages/Stores/Pages/AllStores';
import CameraRecords from '@Pages/CameraRecords';
import NewCameraRecord from '@Pages/CameraRecords/Pages/NewCameraRecord';
import NewVersion from '@Pages/Versions/Pages/NewVersion';
import VersionEdit from '@Pages/Versions/Pages/VersionEdit';
import StoreBoards from '@Pages/Boards/Pages/StoreBoards';
import EditBoard from '@Pages/Boards/Pages/EditBoard';
import AddBoard from '@Pages/Boards/Pages/AddBoard';
import BoardCameras from '@Pages/Cameras/Pages/BoardCameras';
import AddCamera from '@Pages/Cameras/Pages/AddCamera';
import EditCamera from '@Pages/Cameras/Pages/EditCamera';
import NewBeacon from '@Pages/Beacons/Pages/NewBeacon';
import BeaconEdit from '@Pages/Beacons/Pages/BeaconEdit';
import Gateways from '@Pages/Gateways';
import NewGateway from '@Pages/Gateways/Pages/NewGateway';
import GatewayEdit from '@Pages/Gateways/Pages/GatewayEdit';
import AllStoreCameras from '@Pages/Cameras/Pages/AllStoreCameras';
import NotFound from '@Pages/NotFound';
import AccessDenied from '@Pages/AccessDenied';
import PermissionRouter from '@HOC/PermissionRouter';
import { Permissions as Perms } from '@Enums/Permission';
import CameraCoordinates from '@Pages/Cameras/Pages/CameraCoordinates';
import ForgetPassword from '@Pages/ForgetPassword';
import ResetPassword from '@Pages/ResetPassword';
import Receiver from '@Pages/Receiver';
import NewReceiver from '@Pages/Receiver/Pages/NewReceiver';
import ReceiverEdit from '@Pages/Receiver/Pages/ReceiverEdit';
import PageLogs from '@Pages/PageLogs';
import Configs from '@Pages/Settings/Pages/Configs';
import ConfigGrid from '@Pages/Settings/Pages/Configs/Pages/ConfigGrid';
import ConfigEdit from '@Pages/Settings/Pages/Configs/Pages/ConfigEdit';
import ConfigAdd from '@Pages/Settings/Pages/Configs/Pages/ConfigAdd';
import BeaconData from '@Pages/BeaconData';
import AuditLogs from '@Pages/AuditLogs';
import NotificationRules from '@Pages/NotificationRules';
import NewNotificationRule from '@Pages/NotificationRules/Pages/NewNotificationRule';
import FieldSearches from '@Pages/Settings/Pages/FieldSearches';
import EditFieldSearchPage from '@Pages/Settings/Pages/EditFieldSearchPage';
import ReportsPage from '@Pages/ReportsPage';
import CreateReportPage from '@Pages/CreateReportPage';
import EditReportPage from '@Pages/EditReportPage';
import { CreateBeaconRulePage } from '@Pages/CreateBeaconRulePage';
import { BeaconRulesPage } from '@Pages/BeaconRulesPage';
import Region from '@Pages/Settings/Pages/Region';
import CreateRegion from '@Pages/Settings/Pages/Region/Pages/CreateRegion';
import RegionEdit from '@Pages/Settings/Pages/Region/Pages/RegionEdit';
import { HomePage } from '@Pages/HomePage';
import Hardware from '@Pages/Hardware';
import NewHardware from '@Pages/Hardware/Pages/NewHardware';
import HardwareEdit from '@Pages/Hardware/Pages/HardwareEdit';
import Lens from '@Pages/Lens';
import NewLens from '@Pages/Lens/Pages/NewLens';
import LensEdit from '@Pages/Lens/Pages/LensEdit';
import WifiConfigiration from '@Pages/Stores/Pages/WifiConfigirations';
import NetworkConfigiration from '@Pages/Boards/Pages/NetworkConfigiration';
import UnArchive from '@Pages/Brands/Pages/UnArchive';
import StoreUnArchive from '@Pages/Stores/Pages/StoreUnArchive';
import { CreateUserPage } from '@Pages/CreateUserPage';
import { EditUserPage } from '@Pages/EditUserPage';
import { BrandGroupsPage } from '@Pages/BrandGroupsPage';
import { TicketsPage } from '@Pages/TicketsPage';
import { TicketChatPage } from '@Pages/TicketChatPage';
import { StoreCheckinsPage } from '@Pages/StoreCheckinsPage';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/ForgetPassword" element={<ForgetPassword />} />
      <Route path="/Account/ResetPassword">
        <Route path="*" element={<ResetPassword />} />
      </Route>
      <Route
        path="/"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route index element={<HomePage />} />
        <Route path="*" element={<NotFound />} />
        <Route path="access-denied" element={<AccessDenied />} />

        <Route path="brands" element={<Outlets.Brands />}>
          <Route
            index
            element={
              <PermissionRouter permission={Perms.ADMIN_BRAND_VIEW}>
                <AllBrands />
              </PermissionRouter>
            }
          />
          <Route path="new-brand" element={<AddBrand />} />
          <Route path=":brandId" element={<Outlets.Brand />}>
            <Route index element={<EditBrand />} />
            <Route path="unarchive" element={<UnArchive />} />
            <Route path="store-products" element={<StoreProducts />} />
            <Route path="api-key-list" element={<Outlets.ApiKeyList />}>
              <Route index element={<ApiKeyList />} />
              <Route path="new-api-key" element={<AddApiKey />} />
            </Route>

            <Route path="stores" element={<Outlets.Stores />}>
              <Route
                index
                element={
                  <PermissionRouter permission={Perms.ADMIN_STORE_VIEW}>
                    <BrandStores />
                  </PermissionRouter>
                }
              />
              <Route path="new-store" element={<AddStore />} />
              <Route path=":storeId" element={<Outlets.Store />}>
                <Route index element={<EditStore />} />
                <Route path="data" element={<Data />} />
                <Route
                  path="cameras"
                  element={
                    <PermissionRouter permission={Perms.ADMIN_CAMERA_VIEW}>
                      <Outlets.Cameras />
                    </PermissionRouter>
                  }
                >
                  <Route index element={<AllStoreCameras />} />
                  <Route path="new-camera" element={<AddCamera />} />
                  <Route path=":cameraId" element={<Outlets.Camera />}>
                    <Route index element={<EditCamera />} />
                  </Route>
                </Route>
                <Route path="connect-cameras" element={<ConnectCameras />} />
                <Route path="boards" element={<Outlets.Boards />}>
                  <Route
                    index
                    element={
                      <PermissionRouter permission={Perms.ADMIN_BOARD_VIEW}>
                        <StoreBoards />
                      </PermissionRouter>
                    }
                  />
                  <Route path="new-board" element={<AddBoard />} />
                  <Route path=":boardId" element={<Outlets.Board />}>
                    <Route index element={<EditBoard />} />
                    <Route
                      path="cameras"
                      element={
                        <PermissionRouter permission={Perms.ADMIN_CAMERA_VIEW}>
                          <Outlets.Cameras />
                        </PermissionRouter>
                      }
                    >
                      <Route index element={<BoardCameras />} />
                      <Route path="new-camera" element={<AddCamera />} />
                      <Route path=":cameraId" element={<Outlets.Camera />}>
                        <Route index element={<EditCamera />} />
                      </Route>
                    </Route>
                    <Route
                      path="configiration"
                      element={<NetworkConfigiration />}
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>

        <Route
          path="all-stores"
          element={
            <PermissionRouter permission={Perms.ADMIN_STORE_VIEW}>
              <Outlets.Stores />
            </PermissionRouter>
          }
        >
          <Route index element={<AllStores />} />
          <Route path="services" element={<StoreCheckinsPage />} />
          <Route path=":storeId" element={<Outlets.Store />}>
            <Route index element={<EditStore />} />
            <Route path="unarchive" element={<StoreUnArchive />} />
            <Route
              path="cameras"
              element={
                <PermissionRouter permission={Perms.ADMIN_CAMERA_VIEW}>
                  <AllStoreCameras />
                </PermissionRouter>
              }
            />
            <Route path="connect-cameras" element={<ConnectCameras />} />
            <Route path="wifi-configiration" element={<WifiConfigiration />} />
            <Route path="data" element={<Data />} />
            <Route
              path="boards"
              element={
                <PermissionRouter permission={Perms.ADMIN_BOARD_VIEW}>
                  <Outlets.Boards />
                </PermissionRouter>
              }
            >
              <Route index element={<StoreBoards />} />
              <Route path="new-board" element={<AddBoard />} />
              <Route path=":boardId" element={<Outlets.Board />}>
                <Route index element={<EditBoard />} />

                <Route
                  path="cameras"
                  element={
                    <PermissionRouter permission={Perms.ADMIN_CAMERA_VIEW}>
                      <Outlets.Cameras />
                    </PermissionRouter>
                  }
                >
                  <Route index element={<BoardCameras />} />
                  <Route path="new-camera" element={<AddCamera />} />

                  <Route path=":cameraId" element={<Outlets.Camera />}>
                    <Route index element={<EditCamera />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>

        <Route
          path="all-boards"
          element={
            <PermissionRouter permission={Perms.ADMIN_BOARD_VIEW}>
              <Outlets.Boards />
            </PermissionRouter>
          }
        >
          <Route index element={<AllBoards />} />
          <Route path=":boardId" element={<Outlets.Board />}>
            <Route index element={<EditBoard />} />
            <Route
              path="cameras"
              element={
                <PermissionRouter permission={Perms.ADMIN_CAMERA_VIEW}>
                  <Outlets.Cameras />
                </PermissionRouter>
              }
            >
              <Route index element={<BoardCameras />} />
              <Route path="new-camera" element={<AddCamera />} />
              <Route path=":cameraId" element={<Outlets.Camera />}>
                <Route index element={<EditCamera />} />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route
          path="all-cameras"
          element={
            <PermissionRouter permission={Perms.ADMIN_CAMERA_VIEW}>
              <Outlets.Cameras />
            </PermissionRouter>
          }
        >
          <Route index element={<AllCameras />} />
          <Route path="new-camera" element={<AddCamera />} />
          <Route path=":cameraId" element={<Outlets.Camera />}>
            <Route index element={<EditCamera />} />
            <Route path="data" element={<CameraCoordinates />} />
          </Route>
        </Route>

        <Route
          path="users"
          element={
            <PermissionRouter permission={Perms.ADMIN_USERS_VIEW}>
              <Outlets.Users />
            </PermissionRouter>
          }
        >
          <Route index element={<Users />} />
          <Route path="new-user" element={<CreateUserPage />} />
          <Route path=":userId" element={<EditUserPage />} />
        </Route>

        <Route
          path="roles"
          element={
            <PermissionRouter permission={Perms.ADMIN_CUSTOM_ROLE_VIEW}>
              <Outlets.Roles />
            </PermissionRouter>
          }
        >
          <Route index element={<Roles />} />
          <Route path="new-role" element={<NewRole />} />
          <Route path=":roleId" element={<RoleEdit />} />
        </Route>

        <Route
          path="camera-records"
          element={
            <PermissionRouter permission={Perms.ADMIN_CAMERA_RECORDS_VIEW}>
              <Outlets.CameraRecords />
            </PermissionRouter>
          }
        >
          <Route index element={<CameraRecords />} />
          <Route path="new-camera-record" element={<NewCameraRecord />}>
            <Route path=":params" element={<NewCameraRecord />} />
          </Route>
        </Route>

        <Route
          path="data"
          element={
            <PermissionRouter permission={Perms.ADMIN_COORDINATES_VIEW}>
              <Outlets.Data />
            </PermissionRouter>
          }
        >
          <Route index element={<Data />} />
        </Route>

        <Route path="beacondata" element={<Outlets.BeaconData />}>
          <Route index element={<BeaconData />} />
        </Route>
        <Route
          path="versions"
          element={
            <PermissionRouter envAdminCheck>
              <Outlets.Versions />
            </PermissionRouter>
          }
        >
          <Route index element={<Versions />} />
          <Route path="new-version" element={<NewVersion />} />
          <Route path=":versionId" element={<VersionEdit />} />
        </Route>

        <Route path="receiver" element={<Outlets.Receiver />}>
          <Route index element={<Receiver />} />
          <Route path="new-receiver" element={<NewReceiver />} />
          <Route path=":receiverId" element={<ReceiverEdit />} />
        </Route>

        <Route path="beacon" element={<Outlets.Beacons />}>
          <Route index element={<Beacons />} />
          <Route path="new-beacon" element={<NewBeacon />} />
          <Route path=":beaconId" element={<BeaconEdit />} />
          <Route path=":beaconId/data" element={<BeaconData />} />
        </Route>

        <Route path="gateways" element={<Outlets.Gateways />}>
          <Route index element={<Gateways />} />
          <Route path="new-gateway" element={<NewGateway />} />
          <Route path=":gatewayId" element={<GatewayEdit />} />
        </Route>

        <Route
          path="configs"
          element={
            <PermissionRouter envAdminCheck>
              <Outlets.Configs />
            </PermissionRouter>
          }
        >
          <Route path="" element={<Configs />}>
            <Route path=":entity" element={<ConfigGrid />} />
            <Route path=":entity/:configId" element={<ConfigEdit />} />
            <Route path=":entity/new" element={<ConfigAdd />} />
          </Route>
        </Route>

        <Route
          path="page-logs"
          element={
            <PermissionRouter sysAdminCheck>
              <PageLogs />
            </PermissionRouter>
          }
        />
        <Route
          path="audit-logs"
          element={
            <PermissionRouter sysAdminCheck>
              <AuditLogs />
            </PermissionRouter>
          }
        />

        <Route
          path="notification-rules"
          element={
            <PermissionRouter sysAdminCheck>
              <Outlets.NotificationRule />
            </PermissionRouter>
          }
        >
          <Route index element={<NotificationRules />} />
          <Route path="new" element={<NewNotificationRule />} />
        </Route>

        <Route
          path="field-searches"
          element={
            <PermissionRouter sysAdminCheck>
              <Outlet />
            </PermissionRouter>
          }
        >
          <Route index element={<FieldSearches />} />
          <Route path=":fieldSearchId" element={<EditFieldSearchPage />} />
        </Route>

        <Route
          path="beacon-rules"
          element={
            <PermissionRouter permission={Perms.ADMIN_BEACON_RULES_VIEW}>
              <Outlet />
            </PermissionRouter>
          }
        >
          <Route index element={<BeaconRulesPage />} />
          <Route path="create" element={<CreateBeaconRulePage />} />
        </Route>

        <Route
          path="reports"
          element={
            <PermissionRouter sysAdminCheck>
              <Outlet />
            </PermissionRouter>
          }
        >
          <Route index element={<ReportsPage />} />
          <Route path="new" element={<CreateReportPage />} />
          <Route path=":reportId" element={<EditReportPage />} />
        </Route>

        <Route
          path="region"
          element={
            <PermissionRouter permission={Perms.ADMIN_REGION_VIEW}>
              <Outlets.Region />
            </PermissionRouter>
          }
        >
          <Route index element={<Region />} />
          <Route path="new" element={<CreateRegion />} />
          <Route path="brand/:brandId" element={<RegionEdit />} />
        </Route>

        <Route
          path="hardware"
          element={
            <PermissionRouter sysAdminCheck>
              <Outlets.Hardware />
            </PermissionRouter>
          }
        >
          <Route index element={<Hardware />} />
          <Route path="new" element={<NewHardware />} />
          <Route path=":hardwareId" element={<HardwareEdit />} />
        </Route>

        <Route
          path="lens"
          element={
            <PermissionRouter sysAdminCheck>
              <Outlets.Lens />
            </PermissionRouter>
          }
        >
          <Route index element={<Lens />} />
          <Route path="new" element={<NewLens />} />
          <Route path=":lensId" element={<LensEdit />} />
        </Route>

        <Route path="brand-groups" element={<BrandGroupsPage />} />

        <Route path="tickets" element={<Outlets.Ticket />}>
          <Route index element={<TicketsPage />} />
          <Route path=":ticketId">
            <Route path="chat" element={<TicketChatPage />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;

import { BeaconFilterParams, StoreBeaconsDataSerial } from '@Types/Beacon';
import { FetchedBoardsResponse, NewBoard } from '@Types/Board';
import { StoreProduct } from '@Types/Brand';
import {
  AuthorizedPerson,
  CameraStatuses,
  FetchedCamerasResponse,
  GetCamerasParams,
} from '@Types/Camera';
import { CoordinatesFilterParams, CameraCoordinate } from '@Types/Coordinates';
import { PaginationParams } from '@Types/Filter';
import {
  CheckInOutsResponse,
  EditedStore,
  FetchedStoresResponse,
  GeoCoordStoresResponse,
  NewStore,
  ResponseStore,
  Store,
} from '@Types/Store';
import model from './index';
import { noPaginationParams } from '@Hooks/usePagination.ts/constants';

class StoresService extends model {
  constructor() {
    super();
    this.Prefix('Stores');
  }

  /**
   * @param {number} storeId
   * @description Unarchive a store
   * @returns Promise
   */
  async UnarchiveStore(storeId: number) {
    return this.PrefixId(storeId).Post('UnArchive').offset().Save();
  }

  /**
   * @param {number} storeId
   * @description Archive a store
   * @returns Promise
   */
  async ArchiveStore(storeId: number) {
    return this.PrefixId(storeId).Post('Archive').offset().Save();
  }

  /**
   * @param {string} storeId
   * @description Get store details
   * @returns Promise
   */
  async GetStoreDetails(storeId: string) {
    return this.PrefixId(storeId).Get().offset().Save<Store>();
  }

  /**
   * @param {NewStore} store
   * @description Add a new store
   * @returns Promise
   */
  async AddNewStore({ Coordinates, ...store }: NewStore) {
    return this.Post()
      .Data({
        ...store,
        Lat: Coordinates?.lat.toString(),
        Lng: Coordinates?.lng.toString(),
      })
      .Save<ResponseStore>();
  }

  /**
   * @param {EditedStore} store
   * @param {number} storeId
   * @description Edit a store
   * @returns Promise
   */
  async EditStore({ Coordinates, ...store }: EditedStore, storeId: number) {
    return this.PrefixId(storeId)
      .Put()
      .Data({
        ...store,
        Lat: Coordinates?.lat.toString(),
        Lng: Coordinates?.lng.toString(),
      })
      .offset()
      .Save();
  }

  /**
   * @param {StoreProduct[]} storeProducts
   * @description Edit a store
   * @returns Promise
   */
  async EditStoreProducts(storeProducts: StoreProduct[]) {
    return this.Post('EditProducts').Data(storeProducts).Save();
  }

  /**
   * @param {PaginationParams} params
   * @description Get all available unarchived stores
   * @returns Promise
   */
  async GetAllUnarchived(params: PaginationParams) {
    return this.Post('AllUnArchived')
      .Data(params)
      .offset()
      .Save<FetchedStoresResponse>();
  }

  async GetGeoCoordStores() {
    return this.Post('AllUnArchived')
      .Params({ geoCoorsOnly: 1 })
      .Data(noPaginationParams)
      .offset()
      .Save<GeoCoordStoresResponse>();
  }

  /**
   * @param {PaginationParams} params
   * @description Get all available unarchived stores
   * @returns Promise
   */
  async GetAllArchived(params: PaginationParams) {
    return this.Post('AllArchived')
      .Data(params)
      .offset()
      .Save<FetchedStoresResponse>();
  }

  /**
   * @param {PaginationParams} params
   * @param {number} storeId
   * @description Get archived boards
   * @returns FetchedBoardsResponse
   */
  async GetArchivedBoards(params: PaginationParams, storeId: string) {
    return this.PrefixId(storeId)
      .Post('ArchivedBoards')
      .Data(params)
      .offset()
      .Save<FetchedBoardsResponse>();
  }

  /**
   * @param {PaginationParams} params
   * @param {string} storeId
   * @description Get unarchived stores
   * @returns Promise
   */
  async GetUnArchivedBoards(params: PaginationParams, storeId: string) {
    return this.PrefixId(storeId)
      .Post('UnArchivedBoards')
      .Data(params)
      .offset()
      .Save<FetchedBoardsResponse>();
  }

  /**
   * @param {NewBoard} board
   * @param {number} storeId
   * @description Add new board
   * @returns Promise
   */
  async AddNewBoard(board: NewBoard, storeId: number) {
    return this.PrefixId(storeId).Post('Boards').Data(board).offset().Save();
  }

  /**
   * @param {number} storeId
   * @param {AuthorizedPerson} authorizedPerson
   * @description Set authorized person to a cameras
   * @returns Promise
   */
  async SetAuthorizedPersonToCameras(
    storeId: number,
    authorizedPerson: AuthorizedPerson
  ) {
    return this.PrefixId(storeId)
      .Post('SetAuthorizedPersonToCameras')
      .Data(authorizedPerson)
      .Save();
  }

  /**
   * @param {GetCamerasParams} params
   * @description Get all available unarchived cameras
   * @returns Promise
   */
  async GetAllUnarchivedCameras(params: GetCamerasParams) {
    return this.PrefixId(params.storeId!)
      .Post('Cameras/AllUnArchived')
      .Data(params.filters)
      .offset()
      .Save<FetchedCamerasResponse>();
  }

  /**
   * @param {GetCamerasParams} params
   * @description Get all available archived cameras
   * @returns Promise
   */
  async GetAllArchivedCameras(params: GetCamerasParams) {
    return this.PrefixId(params.storeId!)
      .Post('Cameras/AllArchived')
      .Data(params.filters)
      .offset()
      .Save<FetchedCamerasResponse>();
  }

  /**
   * @param {CoordinatesFilterParams} params
   * @description Get Stores Camera Coordinates
   * @returns Promise
   */

  async GetStoresCameraCoordinates(param: CoordinatesFilterParams) {
    //Type : 0: coordinate,
    //Type : 1: line,
    const { storeId, type, filterNumber, period } = param;
    return this.PrefixId(storeId)
      .Get('CameraCoordinates')
      .Params({
        type,
        filterNumber,
        period,
      })
      .offset()
      .Save<CameraCoordinate>();
  }
  /**
   * @param {CoordinatesFilterParams} params
   * @description Get Stores Camera Statuses
   * @returns Promise
   */

  async GetStoresCameraStatus(param: CoordinatesFilterParams) {
    const { storeId, type, filterNumber, period } = param;
    return this.PrefixId(storeId)
      .Get('CameraStatuses')
      .Params({
        type,
        filterNumber,
        period,
      })
      .offset()
      .Save<CameraStatuses>();
  }
  /**
   * @param {BeaconFilterParams} params
   * @description Get Stores Beacon Data
   * @returns Promise
   */

  async GetStoresBeaconsData(param: BeaconFilterParams) {
    const { storeId, filterNumber, period } = param;
    return this.PrefixId(storeId)
      .Get('BeaconsData')
      .Params({
        filterNumber,
        period,
      })
      .offset()
      .Save<StoreBeaconsDataSerial>();
  }

  async GetCheckInOuts(params: PaginationParams) {
    return this.Post('CheckInOuts')
      .Data(params)
      .offset()
      .Save<CheckInOutsResponse>();
  }
}

export default StoresService;

import useDataDisplay from '@Hooks/useDataDisplay';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getPageLogs } from '@Store/User/action';
import React, { useState } from 'react';
import { convertDateTimeParam } from '@Utils/Api';
import { Card, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import CardGridWrapper from '@Components/CardGridWrapper';
import SortByFilter from './Components/SortByFilter';
import DataDisplayTable from '@Components/DataDisplayTable';
import PageLogCard from '@Cards/PageLogCard';
import './style.scss';
import usePageLogsColumns from './usePageLogsColumns';
import DateRangeSelector, { Dates } from '@Components/DateRangeSelector';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';
import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import { FilterOption } from '@Types/Pagination';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';
import Status from '@Enums/Status';
import { useUserSelectList } from '@Hooks/api';
import moment, { Moment } from 'moment';
import TimeRangeSelector from '@Components/TimeRangeSelector';

type RangeValue = [Moment | null, Moment | null] | null;

export default function PageLogs() {
  const [sdate, setSDate] = useState<string | null>(
    moment().subtract(2, 'weeks').format('DD/MM/YYYY')
  );
  const [edate, setEDate] = useState<string | null>(
    moment().format('DD/MM/YYYY')
  );
  const [stime, setSTime] = useState<string | null>('10:00');
  const [etime, setETime] = useState<string | null>('23:00');
  const [dates, setDates] = useState<RangeValue>([
    moment().subtract(2, 'weeks').set('hour', 10).set('minute', 0),
    moment().set('hour', 23).set('minute', 0),
  ]);

  const [currentDisplay, toggleDisplay] = useDataDisplay('pageLogs');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const pageLogs = useAppSelector(state => state.User.pageLogs);

  const allUsers = useUserSelectList();

  const filterOptions: FilterOption[] = [
    {
      field: 'CreatedByUser.Id',
      label: t('user'),
      type: FilterType.TEXT_ARRAY_SELECT,
      loading: allUsers?.status === Status.pending,
      options: allUsers?.data?.map(u => ({
        label: u.Text,
        value: u.Value,
      })),
    },
    { field: 'PageUrl', label: 'BT Mac Address', type: FilterType.TEXT },
    {
      field: 'BrandId',
      label: t('brand') + ' ID',
      type: FilterType.NUMBER,
    },
    { field: 'Brand.Name', label: t('brand'), type: FilterType.TEXT },
    { field: 'StoreId', label: t('store') + ' ID', type: FilterType.NUMBER },
    { field: 'Store.Name', label: t('store'), type: FilterType.TEXT },
  ];

  const pageConfigResult = useUserPageConfig(
    'pageLogs',
    {
      orderBy: 'asc',
      sortKey: 'CreatedByUser.UserName',
    },
    { fields: [...filterOptions.map(o => o.field), 'CreatedByUser.UserName'] }
  );

  const { pagination } = usePaginatedQuery({
    status: pageLogs.status,
    filterOptions,
    pageConfigResult,
    refetchDependencies: [sdate, edate, stime, etime],
    queryFunction: p => {
      if (!sdate || !edate || !stime || !etime) return;
      dispatch(
        getPageLogs({
          filters: p,
          dateTimeParams: convertDateTimeParam({ sdate, edate, stime, etime }),
        })
      );
    },
  });

  const pageLogsTableColumns = usePageLogsColumns({ pageLogs });

  const handleDateSelect = (dates: Dates) => {
    if (dates) {
      let [firstDate, secondDate] = dates;
      setSDate(firstDate ? firstDate.format('DD/MM/YYYY') : null);
      setEDate(secondDate ? secondDate.format('DD/MM/YYYY') : null);
    }
  };

  const handleTimeSelect = (
    _: Dates,
    [newSTime, newETime]: [string, string]
  ) => {
    setSTime(newSTime);
    setETime(newETime);
  };

  const disabledDate = (current: Moment) => {
    if (!dates) {
      return false;
    }

    const tooLate = dates[0] && current.diff(dates[0], 'months') >= 3;
    const tooEarly = dates[1] && dates[1].diff(current, 'months') >= 3;

    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    }
  };

  useSetHeaderTitle(t('pageLogs'));

  return (
    <Card className="page-logs-detail-container">
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter
            onChange={pagination.onSortByChange}
            defaultValue={pagination.SortBy}
          />
          <PaginationOrderByFilter
            onChange={pagination.onOrderByChange}
            defaultValue={pagination.OrderBy}
          />
          <PaginationFilter
            filterOptions={filterOptions}
            pagination={pagination}
          />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          <DateRangeSelector
            value={dates}
            disabledDate={disabledDate}
            onChange={handleDateSelect}
            onCalendarChange={val => setDates(val)}
            onOpenChange={onOpenChange}
          />
          <TimeRangeSelector
            value={[moment(stime, 'HH:mm'), moment(etime, 'HH:mm')]}
            onChange={handleTimeSelect}
          />
        </div>

        <CardGridWrapper
          status={pageLogs.status}
          noData={pageLogs.data?.Data?.length === 0}
          cards={
            currentDisplay === 'card' ? (
              <React.Fragment>
                {pageLogs.data?.Data?.map((pageLog, i) => (
                  <PageLogCard pageLog={pageLog} key={i} />
                ))}
              </React.Fragment>
            ) : (
              <DataDisplayTable
                columns={pageLogsTableColumns}
                dataSource={pageLogs.data?.Data}
              />
            )
          }
        />

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pagination.PageIndex}
          onChange={pagination.onPageIndexChange}
          pageSize={pageLogs.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={pageLogs.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
}

import { FilterOperation, OrderBy } from '@Enums/Filter';
import { FilterBy, FilterItem, FilterOption } from '@Types/Pagination';
import { isMoment } from 'moment';
import { FilterType } from './PaginationFilter';

export function createFilterBy(
  field: string,
  operation: FilterOperation,
  filterValue: FilterItem['value']
) {
  let value = filterValue;
  if (isMoment(filterValue)) {
    value = filterValue.format('YYYY-MM-DD');
  } else if (Array.isArray(filterValue)) {
    value = `[${filterValue.join(',')}]`;
  }

  return `${field} ${operation} ${value}`;
}

export function createOrderBy(field: string, operation: OrderBy) {
  return `${field} ${operation}`;
}

export function convertFiltersToApiFormat(
  filters: (FilterOption & FilterBy[number])[]
): string[] {
  return filters
    .filter(
      filterItem =>
        filterItem.field !== undefined &&
        filterItem.value !== undefined &&
        filterItem.value !== null &&
        (Array.isArray(filterItem.value) ? filterItem.value.length > 0 : true)
    )
    .map(filterItem => {
      let operation = FilterOperation.Equal;

      switch (filterItem.type) {
        case FilterType.TEXT:
        case FilterType.NUMBER_ARRAY:
        case FilterType.NUMBER_ARRAY_SELECT:
        case FilterType.TEXT_ARRAY:
        case FilterType.TEXT_ARRAY_SELECT:
        case FilterType.TEXT_SELECT:
          operation = FilterOperation.Contains;
          break;
      }

      return createFilterBy(filterItem.field!, operation, filterItem.value);
    });
}

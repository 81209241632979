import { Option } from 'antd/lib/mentions';
import {
  TagOutlined,
  NumberOutlined,
  HomeOutlined,
  ShopOutlined,
  CalendarOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import SortByFilter from '@Components/SortByFilter';

type Props = {
  onChange: (value: string) => void;
  defaultValue: string;
};

const SortByFilterPageLogs = ({ onChange, defaultValue }: Props) => {
  const { t } = useTranslation();
  const iconStyle = { paddingLeft: 6 };

  return (
    <SortByFilter defaultValue={defaultValue} onChange={onChange}>
      <Option value="CreatedByUser.UserName">
        {t('user')}
        <TagOutlined style={iconStyle} />
      </Option>
      <Option value="PageUrl">
        {t('URL')}
        <LinkOutlined style={iconStyle} />
      </Option>
      <Option value="BrandId">
        {t('BrandId')}
        <HomeOutlined style={iconStyle} />
      </Option>
      <Option value="Brand.Name">
        {t('brand')}
        <HomeOutlined style={iconStyle} />
      </Option>
      <Option value="StoreId">
        {t('StoreId')}
        <ShopOutlined style={iconStyle} />
      </Option>
      <Option value="Store.Name">
        {t('store')}
        <ShopOutlined style={iconStyle} />
      </Option>
      <Option value="CreatedDate">
        {t('createdDate')}
        <CalendarOutlined style={iconStyle} />
      </Option>
    </SortByFilter>
  );
};

export default SortByFilterPageLogs;

import { HomepageTag } from '@Features/homepage/homepage.enums';
import { useHomepageContext } from '@Features/homepage/hooks';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { TAGS_QUERY_PARAM } from '@Features/homepage/homepage.constants';

export function TagFilters() {
  const { tags, setTags, setQueryParam, deleteQueryParam } =
    useHomepageContext();

  const { t } = useTranslation();

  function handleTagChange(value: HomepageTag) {
    return () => {
      setTags(prevS => {
        const newTags = prevS.includes(value)
          ? prevS.filter(t => t !== value)
          : [...prevS, value];

        if (newTags.length) {
          setQueryParam(TAGS_QUERY_PARAM, JSON.stringify(newTags));
        } else {
          deleteQueryParam(TAGS_QUERY_PARAM);
        }

        return newTags;
      });
    };
  }

  const rows = [
    [
      {
        label: t('off'),
        color: 'rgb(243, 122, 169)',
        passiveBorderColor: 'rgba(244,141,180,0.3)',
        bgColor: 'rgba(244,141,180,0.25)',
        value: HomepageTag.OFF,
      },
      {
        label: t('longOff'),
        color: 'rgb(176, 48, 82)',
        passiveBorderColor: 'rgba(176, 48, 82,0.3)',
        bgColor: 'rgba(176, 48, 82,0.15)',
        value: HomepageTag.THREE_DAYS_PASSIVE,
      },
      {
        label: t('uninstalled'),
        color: 'rgb(238, 167, 37)',
        passiveBorderColor: 'rgba(253, 196, 89,0.4)',
        bgColor: 'rgba(253, 196, 89,0.25)',
        value: HomepageTag.NOT_INSTALLED,
      },
      {
        label: t('on'),
        color: 'rgb(56, 194, 132)',
        passiveBorderColor: 'rgba(56, 194, 132,0.4)',
        bgColor: 'rgba(126, 212, 173,0.25)',
        value: HomepageTag.ON,
      },
    ],
  ];

  return (
    <div className="homepage-tags">
      {rows.map((row, i) => (
        <div key={i} className="tag-row">
          {row.map(tag => (
            <Tag
              key={tag.value}
              className={`homepage-tag ${
                tags.includes(tag.value) ? 'active-tag' : ''
              }`}
              onClick={handleTagChange(tag.value)}
              style={{
                color: tag.color,
                backgroundColor: tag.bgColor,
                borderColor: tags.includes(tag.value)
                  ? tag.color
                  : tag.passiveBorderColor,
              }}
            >
              {tag.label}
            </Tag>
          ))}
        </div>
      ))}
    </div>
  );
}

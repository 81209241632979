import { Card, Form, Input } from 'antd';
import { useParams } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import './style.scss';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useForm } from 'antd/lib/form/Form';
import ModalHeader from '@Pages/Users/Components/ModalHeader';
import ModalFooter from '@Components/ModalFooter';
import { EditRole, Role } from '@Types/Role';
import { getAllRoles } from '@Store/Role/action';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import RoleService from '@Services/Api/Role';
import usePermissions from '@Hooks/usePermissions';
import Permissions from '../../Components/Permissions';
import { apiCall, checkboxPageUpdater } from '@Utils/index';
import { Permissions as PermissionsEnum } from '@Enums/Permission';

const RoleEdit = () => {
  const [role, setRole] = useState<Role>();
  const roles = useAppSelector(state => state.Role.allRoles);
  const dispatch = useAppDispatch();
  const perms = usePermissions();

  useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  useEffect(() => {
    const currentRole = roles.data.find(u => u.RoleId === roleId);
    setRole(currentRole);
  }, [roles.status]);

  useEffect(
    () => form.resetFields(),
    [role?.RoleId, perms.status, roles.status]
  );

  let { roleId } = useParams();
  const [form] = useForm();
  const { t } = useTranslation();
  let navigate = useNavigate();

  const handleSubmit = async (roleDetail: EditRole) => {
    const editedRole: EditRole = {
      RoleId: role?.RoleId!,
      RoleName: roleDetail.RoleName,
      PermissionIds: roleDetail.Permissions?.map(
        p => perms.data.find(d => d.Name === p)?.Id!
      )!,
    };
    try {
      await apiCall(() => editRole(editedRole), 'editRole');
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const editRole = async (roleDetail: EditRole) => {
    return await new RoleService().Edit(roleDetail);
  };

  const inputRule = [{ required: true, message: t('cannotBeEmpty') }];

  const isPermGrantedForRole = (permName: string): boolean => {
    return role?.Permissions.findIndex(p => p.Name === permName) !== -1;
  };

  const intlValues = {
    RoleName: role?.RoleName,
    Permissions: perms.data
      .filter(p => isPermGrantedForRole(p.Name))
      .map(p => p.Name),
  };

  const onCheckboxChange = (e: CheckboxChangeEvent): void => {
    let Permissions = form.getFieldValue('Permissions') as string[];
    const isChecked = e.target.checked;
    const permission = e.target.value as string;

    const permIndex = Permissions.findIndex(p => p === permission);

    if (isChecked && permIndex === -1) {
      Permissions.push(permission);
    } else {
      Permissions.splice(permIndex, 1);
    }

    // Make sure diagnify application permission is selected if diagnify process without checkin is selected
    if (
      Permissions.includes(PermissionsEnum.DIAGNIFY_PROCESS_WITHOUTCHECKIN) &&
      !Permissions.includes(PermissionsEnum.DIAGNIFY_APPLICATION)
    )
      Permissions = checkboxPageUpdater({
        mainPage: PermissionsEnum.DIAGNIFY_APPLICATION,
        subPages: [PermissionsEnum.DIAGNIFY_PROCESS_WITHOUTCHECKIN],
        values: Permissions,
      });

    // Make sure main personnel page is selected if any sub-pages are selected
    Permissions = checkboxPageUpdater({
      mainPage: PermissionsEnum.PANEL_PAGE_PERSONNEL,
      subPages: [
        PermissionsEnum.PANEL_PAGE_PERSONNEL_PERSONNEL,
        PermissionsEnum.PANEL_PAGE_RECEIVER,
      ],
      values: Permissions,
    });

    // Make sure main settings page is selected if any sub-pages are selected
    Permissions = checkboxPageUpdater({
      mainPage: PermissionsEnum.PANEL_PAGE_SETTINGS,
      subPages: [
        PermissionsEnum.PANEL_PAGE_SETTINGS_CAMERA,
        PermissionsEnum.PANEL_PAGE_SETTINGS_NOTIFICATIONS,
        PermissionsEnum.PANEL_PAGE_SETTINGS_DEPARTMENT,
        PermissionsEnum.PANEL_PAGE_SETTINGS_WORKING_HOURS,
        PermissionsEnum.PANEL_PAGE_SETTINGS_SALES_UPLOADING,
        PermissionsEnum.PANEL_PAGE_SETTINGS_CAMERA_SNAPSHOT,
        PermissionsEnum.PANEL_PAGE_SETTINGS_FORECAST,
        PermissionsEnum.PANEL_PAGE_SETTINGS_CAMPAIGN,
        PermissionsEnum.PANEL_PAGE_SETTINGS_IFRAME,
        PermissionsEnum.PANEL_PAGE_SETTINGS_UPLOAD_REPORTS,
        PermissionsEnum.PANEL_PAGE_SETTINGS_SKETCH,
        PermissionsEnum.PANEL_PAGE_SETTINGS_REPORTS,
      ],
      values: Permissions,
    });

    form.setFieldsValue({ Permissions });
  };

  const values = (Form.useWatch('Permissions', form) as string[]) || [];

  return (
    <Card
      title={
        <ModalHeader titleKey="editRole">
          <EditOutlined />
        </ModalHeader>
      }
      className="role-edit-wrapper"
    >
      <Form
        form={form}
        name="role-info-form"
        id="role-info-form"
        initialValues={intlValues}
        onFinish={handleSubmit}
        className="role-edit-container"
      >
        <Form.Item label={t('name')} name="RoleName" rules={inputRule}>
          <Input />
        </Form.Item>

        <Permissions
          values={values}
          intlValues={intlValues}
          onCheckboxChange={onCheckboxChange}
        />

        <ModalFooter formKey="role-info-form" />
      </Form>
    </Card>
  );
};

export default RoleEdit;

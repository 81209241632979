import React, { useRef } from 'react';
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  StandaloneSearchBox,
  Libraries,
} from '@react-google-maps/api';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.scss';

const containerStyle = {
  width: 'auto',
  height: '400px',
};

const libraries: Libraries = ['places'];

// rvl office
const center = {
  lat: 41.058325023151575,
  lng: 28.9777564692437,
};

interface LocationPickerProps {
  defaultCoordinates?: {
    lat: number;
    lng: number;
  };
  onMarkerChange: (values: { lat: number; lng: number }) => void;
}

/**
 * @see https://www.npmjs.com/package/@react-google-maps/api for reference example of this component
 */
export function LocationPicker(props: LocationPickerProps) {
  const { defaultCoordinates, onMarkerChange } = props;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  });

  const [map, setMap] = React.useState<google.maps.Map>();
  const [latLng, setLatLng] = React.useState<google.maps.LatLng | undefined>();

  const searchBoxRef = useRef<google.maps.places.SearchBox>();

  const { t } = useTranslation();

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    setMap(map);
    setLatLng(
      defaultCoordinates
        ? new google.maps.LatLng(defaultCoordinates.lat, defaultCoordinates.lng)
        : undefined
    );
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(undefined);
  }, []);

  function handleLatLngChange(lat: number, lng: number) {
    setLatLng(new google.maps.LatLng(lat, lng));
    onMarkerChange({
      lat,
      lng,
    });
  }

  function handleMapClick(e: google.maps.MapMouseEvent) {
    if (!e.latLng) return;

    handleLatLngChange(e.latLng.lat(), e.latLng.lng());
  }

  function onSearchBoxLoad(ref: google.maps.places.SearchBox) {
    searchBoxRef.current = ref;
  }

  function onPlacesChanged() {
    const places = searchBoxRef.current?.getPlaces();
    const place = places?.[0];
    const location = place?.geometry?.location;
    const lat = location?.lat();
    const lng = location?.lng();

    if (!(lat && lng)) return;

    handleLatLngChange(lat, lng);
    map?.setCenter({ lat, lng });
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={defaultCoordinates ?? center}
      zoom={13}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={handleMapClick}
    >
      {latLng && (
        <Marker position={latLng} onClick={() => setLatLng(undefined)} />
      )}
      <StandaloneSearchBox
        onLoad={onSearchBoxLoad}
        onPlacesChanged={onPlacesChanged}
      >
        <Input
          className="location-picker-search"
          placeholder={t('search...')}
          style={{
            width: 250,
            height: 40,
            position: 'absolute',
            top: 10,
            right: 58,
          }}
        />
      </StandaloneSearchBox>
    </GoogleMap>
  ) : (
    <></>
  );
}

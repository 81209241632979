import { createReducer } from '@reduxjs/toolkit';
import {
  getAllUsers,
  getCurrentUser,
  setSelectedUser,
  getUdentifyUsers,
  getUserDetails,
  getPageLogs,
  getUserStores,
  resetUserStores,
  getStoreUsers,
  setUserDetails,
  getUserSelectList,
} from './action';
import { asyncFetchInterface } from '../../Types/ReduxTypes';
import {
  AllUsers,
  AllUsersFetchResponse,
  UserDetail,
  UserEmails,
  UserFetchResponse,
  UserSelectListItem,
  UserStoresEmails,
} from '@Types/User';
import { AllPageLogs, AllPageLogsFetchResponse } from '@Types/PageLogs';
interface StateInterface {
  allUsers: AllUsers;
  selectedUser: asyncFetchInterface<UserFetchResponse>;
  udentifyUsers: asyncFetchInterface<UserFetchResponse[]>;
  currentUserDetails: asyncFetchInterface<UserDetail>;
  pageLogs: AllPageLogs;
  userStoresAndEmails: asyncFetchInterface<UserStoresEmails>;
  storeUsers: asyncFetchInterface<UserEmails[]>;
  selectList: asyncFetchInterface<UserSelectListItem[]>;
}

const initialState: StateInterface = {
  allUsers: {
    data: {} as AllUsersFetchResponse,
    status: 'Empty',
  },
  selectedUser: {
    data: {} as UserFetchResponse,
    status: 'Empty',
  },
  udentifyUsers: {
    data: [] as UserFetchResponse[],
    status: 'Empty',
  },
  currentUserDetails: {
    data: {} as UserDetail,
    status: 'Empty',
  },
  pageLogs: {
    data: {} as AllPageLogsFetchResponse,
    status: 'Empty',
  },
  userStoresAndEmails: {
    data: [],
    status: 'Empty',
  },
  storeUsers: {
    data: [] as UserEmails[],
    status: 'Empty',
  },
  selectList: {
    data: [],
    status: 'Empty',
  },
};

const UserReducer = createReducer(initialState, builder => {
  builder
    .addCase(getAllUsers.pending, state => {
      state.allUsers = {
        ...state.allUsers,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllUsers.fulfilled, (state, action) => {
      state.allUsers = {
        status: 'fulfilled',
        data: action.payload as AllUsersFetchResponse,
      };
    })
    .addCase(getAllUsers.rejected, (state, action) => {
      state.allUsers = {
        ...state.allUsers,
        data: {} as AllUsersFetchResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getUdentifyUsers.pending, state => {
      state.udentifyUsers = {
        ...state.udentifyUsers,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getUdentifyUsers.fulfilled, (state, action) => {
      state.udentifyUsers = {
        status: 'fulfilled',
        data: action.payload as UserFetchResponse[],
      };
    })
    .addCase(getUdentifyUsers.rejected, (state, action) => {
      state.udentifyUsers = {
        ...state.udentifyUsers,
        data: [] as UserFetchResponse[],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getCurrentUser.pending, state => {
      state.selectedUser = {
        ...state.selectedUser,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getCurrentUser.fulfilled, (state, action) => {
      state.selectedUser = {
        status: 'fulfilled',
        data: action.payload as UserFetchResponse,
      };
    })
    .addCase(getCurrentUser.rejected, (state, action) => {
      state.selectedUser = {
        ...state.selectedUser,
        data: {} as UserFetchResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getUserDetails.pending, state => {
      state.currentUserDetails = {
        ...state.currentUserDetails,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getUserDetails.fulfilled, (state, action) => {
      state.currentUserDetails = {
        status: 'fulfilled',
        data: action.payload as UserDetail,
      };
    })
    .addCase(getUserDetails.rejected, (state, action) => {
      state.currentUserDetails = {
        ...state.currentUserDetails,
        data: {} as UserDetail,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getPageLogs.pending, state => {
      state.pageLogs = {
        ...state.pageLogs,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getPageLogs.fulfilled, (state, action) => {
      state.pageLogs = {
        status: 'fulfilled',
        data: action.payload,
      };
    })
    .addCase(getPageLogs.rejected, (state, action) => {
      state.pageLogs = {
        ...state.pageLogs,
        data: {} as AllPageLogsFetchResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(setSelectedUser, (state, action) => {
      state.selectedUser = {
        status: 'fulfilled',
        data: action.payload as UserFetchResponse,
      };
    })
    .addCase(getUserStores.pending, state => {
      state.userStoresAndEmails = {
        ...state.userStoresAndEmails,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getUserStores.fulfilled, (state, action) => {
      state.userStoresAndEmails = {
        status: 'fulfilled',
        data: action.payload as UserStoresEmails,
      };
    })
    .addCase(getUserStores.rejected, (state, action) => {
      state.userStoresAndEmails = {
        ...state.userStoresAndEmails,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(resetUserStores, state => {
      state.userStoresAndEmails = {
        ...state.userStoresAndEmails,
        data: [],
        status: 'Empty',
        ErrorMessage: undefined,
      };
    })
    .addCase(getStoreUsers.pending, state => {
      state.storeUsers = {
        ...state.storeUsers,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getStoreUsers.fulfilled, (state, action) => {
      state.storeUsers = {
        status: 'fulfilled',
        data: action.payload as UserEmails[],
      };
    })
    .addCase(getStoreUsers.rejected, (state, action) => {
      state.storeUsers = {
        ...state.storeUsers,
        data: [] as UserEmails[],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(setUserDetails, (state, action) => {
      state.currentUserDetails.data = action.payload;
    })
    .addCase(getUserSelectList.pending, state => {
      state.selectList = {
        ...state.selectList,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getUserSelectList.fulfilled, (state, action) => {
      state.selectList = {
        status: 'fulfilled',
        data: action.payload,
      };
    })
    .addCase(getUserSelectList.rejected, (state, action) => {
      state.selectList = {
        ...state.selectList,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    });
});

export default UserReducer;

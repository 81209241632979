import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

export type Dates = [Moment | null, Moment | null] | null;
export type DateStrings = [string, string];

export default function DateRangeSelector(props: RangePickerProps) {
  const { t } = useTranslation();

  return (
    <RangePicker
      placeholder={[t('startDate'), t('endDate')]}
      format="DD/MM/YYYY"
      allowClear={false}
      style={{ width: 230 }}
      {...props}
    />
  );
}

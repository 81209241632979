import {
  BrandSelector,
  DevicesSection,
  GroupSelector,
  HomepageMap,
  StoreSelector,
  TagFilters,
} from '@Features/homepage/components';
import { HomepageContainer } from '@Features/homepage/containers';
import { useAppSelector } from '@Store/hooks';
import { HomepageView } from '@Types/Dashboard';

export function HomePage() {
  const homepageView = useAppSelector(s => s.Dashboard.homepageView);

  const views: Record<HomepageView, React.ReactNode> = {
    list: (
      <>
        <StoreSelector />
        <DevicesSection />
      </>
    ),
    map: <HomepageMap />,
  };

  return (
    <HomepageContainer>
      <div className="group-brand-container">
        <TagFilters />
        <GroupSelector />
        <BrandSelector />
      </div>
      {views[homepageView]}
    </HomepageContainer>
  );
}

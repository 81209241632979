import { Cluster } from '@react-google-maps/marker-clusterer';
import './style.scss';
import { OverlayView } from '@react-google-maps/api';
import { Button, Card, CardProps, List } from 'antd';
import { MarkerLabel } from '@Features/homepage/homepage.types';
import { useMemo, useRef } from 'react';
import { BoardCountInfo } from '@Components/BoardCountInfo';
import { useTranslation } from 'react-i18next';
import { useOutsideClick } from '@Hooks/useOutsideClick';

interface ClusterInfoProps extends CardProps {
  cluster: Cluster;
  locationMarkers: MarkerLabel[];
  onOutsideClick: () => void;
}

export function ClusterInfo(props: ClusterInfoProps) {
  const { cluster, locationMarkers, onOutsideClick, ...restProps } = props;

  const { t } = useTranslation();

  const ref = useRef(null);

  useOutsideClick(ref, onOutsideClick);

  const items = useMemo(() => {
    const result = [];
    const markers = cluster.markers ?? [];

    for (const marker of markers) {
      const locationMarker = locationMarkers.find(
        m => `${m.BrandName} / ${m.StoreName}` === marker.getLabel()
      );

      if (locationMarker) {
        result.push(locationMarker);
      }
    }

    return result;
  }, [cluster.markers, locationMarkers]);

  return (
    <OverlayView
      position={cluster.center}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <Card
        ref={ref}
        className="cluster-info"
        bodyStyle={{ padding: 4 }}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        {...restProps}
      >
        <List
          header={
            <Button
              type="primary"
              block
              onClick={() => {
                cluster.map?.setZoom(21);
                cluster.map?.setOptions({ center: cluster.center });
              }}
            >
              {t('zoomAllTheWay')}
            </Button>
          }
          dataSource={items}
          renderItem={item => (
            <List.Item>
              <div className="cluster-row">
                <p className="cluster-label">{item.name + ':'}</p>
                <BoardCountInfo {...item} />
              </div>
            </List.Item>
          )}
        />
      </Card>
    </OverlayView>
  );
}

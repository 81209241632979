export const NEW_USER = 'USER/NEW';
export const ALL_USERS = 'USER/ALL_USERS';
export const GET_CURRENT_USER = 'USER/GET_CURRENT_USER';
export const GET_UDENTIFY_USERS = 'USER/GET_UDENTIFY_USERS';
export const GET_USER_DETAILS = 'USER/GET_USER_DETAILS';
export const SET_USER_DETAILS = 'USER/SET_USER_DETAILS';
export const GET_PAGE_LOGS = 'USER/GET_PAGE_LOGS';

export const SET_SORT_BY_VALUE = 'USER/SET_SORT_BY_VALUE';
export const SET_CUSTOM_FILTER_VALUE = 'USER/SET_CUSTOM_FILTER_VALUE';
export const SET_PAGINATION_INDEX = 'USER/SET_PAGINATION_INDEX';
export const SET_SELECTED_USER = 'USER/SET_SELECTED_USER';
export const GET_USER_STORES = 'USER/GET_USER_STORES';
export const RESET_USER_STORE = 'USER/RESET_USER_STORE';
export const GET_STORE_USER = 'USER/GET_STORE_USER';
export const GET_USER_SELECT_LIST = 'USER/GET_USER_SELECT_LIST';

import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { PlusCircleFilled } from '@ant-design/icons';
import { Button, Card, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import SortByFilter from './Components/SortByFilter';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getAllRegions } from '@Store/Regions/action';
import Status from '@Enums/Status';
import CardGridWrapper from '@Components/CardGridWrapper';
import React from 'react';
import RegionCard from '@Cards/RegionCard';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';
import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import { FilterOption } from '@Types/Pagination';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';

function Region() {
  const { t } = useTranslation();
  const allRegion = useAppSelector(s => s.Regions.allRegion);

  const filterOptions: FilterOption[] = [
    {
      field: 'BrandId',
      label: t('brand') + ' ID',
      type: FilterType.NUMBER,
    },
    { field: 'Brand.Name', label: t('brand'), type: FilterType.TEXT },
  ];

  const pageConfigResult = useUserPageConfig(
    'region',
    {
      orderBy: 'asc',
      sortKey: 'BrandName',
    },
    { fields: filterOptions.map(o => o.field) }
  );

  const { pagination } = usePaginatedQuery({
    status: allRegion.status,
    filterOptions,
    pageConfigResult,
    queryFunction: p => dispatch(getAllRegions(p)),
  });

  const dispatch = useAppDispatch();

  useSetHeaderTitle(t('region'));

  return (
    <Card className="Region" loading={allRegion.status === Status.pending}>
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter
            onChange={pagination.onSortByChange}
            defaultValue={pagination.SortBy}
          />
          <PaginationOrderByFilter
            onChange={pagination.onOrderByChange}
            defaultValue={pagination.OrderBy}
          />
          <PaginationFilter
            filterOptions={filterOptions}
            pagination={pagination}
          />
          <Link to="/region/new">
            <Button
              type="primary"
              icon={<PlusCircleFilled />}
              className="create-region-button"
            >
              {t('createRegion')}
            </Button>
          </Link>
        </div>
        <div className="card-grid-container">
          <CardGridWrapper
            status={allRegion.status}
            noData={allRegion.data?.Data?.length === 0}
            cards={
              <React.Fragment>
                {allRegion.data?.Data?.map(region => (
                  <RegionCard key={region.BrandId} region={region} />
                ))}
              </React.Fragment>
            }
          />
        </div>
        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pagination.PageIndex}
          onChange={pagination.onPageIndexChange}
          pageSize={allRegion.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={allRegion.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
}

export default Region;

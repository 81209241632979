import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { PlusCircleFilled } from '@ant-design/icons';
import { Button, Card, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import SortByFilter from './Components/SortByFilter';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import Status from '@Enums/Status';
import CardGridWrapper from '@Components/CardGridWrapper';
import React from 'react';
import { getAllLensType } from '@Store/LensType/action';
import LensCard from '@Cards/LensCard';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { FilterOption } from '@Types/Pagination';
import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';

function Lens() {
  const { t } = useTranslation();
  const alllensType = useAppSelector(s => s.LensType.alllensType);

  const filterOptions: FilterOption[] = [
    { field: 'Name', label: t('name'), type: FilterType.TEXT },
  ];

  const pageConfigResult = useUserPageConfig(
    'lens',
    {
      orderBy: 'asc',
      sortKey: 'Name',
    },
    { fields: filterOptions.map(o => o.field) }
  );

  const { pagination, refetch } = usePaginatedQuery({
    status: alllensType.status,
    filterOptions,
    pageConfigResult,
    queryFunction: p => dispatch(getAllLensType(p)),
  });

  const dispatch = useAppDispatch();

  useSetHeaderTitle(t('lens'));

  return (
    <Card className="Lens" loading={alllensType.status === Status.pending}>
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter
            onChange={pagination.onSortByChange}
            defaultValue={pagination.SortBy}
          />
          <PaginationOrderByFilter
            onChange={pagination.onOrderByChange}
            defaultValue={pagination.OrderBy}
          />
          <PaginationFilter
            filterOptions={filterOptions}
            pagination={pagination}
          />
          <Link to="/lens/new">
            <Button
              type="primary"
              icon={<PlusCircleFilled />}
              className="create-lens-button"
            >
              {t('addLens')}
            </Button>
          </Link>
        </div>
        <div className="card-grid-container">
          <CardGridWrapper
            status={alllensType.status}
            noData={alllensType.data?.Data?.length === 0}
            cards={
              <React.Fragment>
                {alllensType.data?.Data?.map(lens => (
                  <LensCard
                    key={lens.Id}
                    lensType={lens}
                    getLensType={refetch}
                  />
                ))}
              </React.Fragment>
            }
          />
        </div>
        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pagination.PageIndex}
          onChange={pagination.onPageIndexChange}
          pageSize={alllensType.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={alllensType.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
}

export default Lens;

import CardGridWrapper from '@Components/CardGridWrapper';
import DataDisplayTable from '@Components/DataDisplayTable';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { Card, Pagination } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import useDataDisplay from '@Hooks/useDataDisplay';
import { getTickets } from '@Store/Ticket/action';
import useTicketsTableColumns from './useTicketsTableColumns';
import { SortByFilterTickets, TicketCard } from '@Features/tickets/components';
import './style.scss';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';
import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import { FilterOption } from '@Types/Pagination';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';
import { enumToIterable } from '@Utils/helpers';
import { TicketStatus } from '@Enums/ticket.enums';
import Status from '@Enums/Status';
import { useUserSelectList } from '@Hooks/api';

export function TicketsPage() {
  const { t } = useTranslation();

  const [currentDisplay, toggleDisplay] = useDataDisplay('tickets');
  const tickets = useAppSelector(state => state.Ticket.tickets);
  const allUsers = useUserSelectList();

  const dispatch = useAppDispatch();

  const filterOptions: FilterOption[] = [
    { field: 'Id', label: 'ID', type: FilterType.NUMBER },
    { field: 'Description', label: t('description'), type: FilterType.TEXT },
    {
      field: 'TicketStatus',
      label: t('ticketStatus'),
      type: FilterType.NUMBER_ARRAY_SELECT,
      options: enumToIterable(TicketStatus).map(([key, value]) => ({
        label: t(`ticketStatuses.${key}`, { defaultValue: key }),
        value: value,
      })),
    },
    {
      field: 'CreatedByUserId',
      label: t('users'),
      type: FilterType.NUMBER_ARRAY_SELECT,
      loading: allUsers?.status === Status.pending,
      options: allUsers?.data?.map(u => ({
        label: u.Text,
        value: u.Value,
      })),
    },
  ];

  const pageConfigResult = useUserPageConfig(
    'tickets',
    {
      orderBy: 'asc',
      sortKey: 'Id',
    },
    { fields: filterOptions.map(o => o.field) }
  );

  const { pagination, refetch } = usePaginatedQuery({
    status: tickets.status,
    filterOptions,
    pageConfigResult,
    queryFunction: p => dispatch(getTickets(p)),
  });

  const ticketsTableColumns = useTicketsTableColumns({
    tickets,
    onRefetch: refetch,
  });

  useSetHeaderTitle(t('tickets'));

  return (
    <Card className="tickets-page">
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilterTickets
            onChange={pagination.onSortByChange}
            defaultValue={pagination.SortBy}
          />
          <PaginationOrderByFilter
            onChange={pagination.onOrderByChange}
            defaultValue={pagination.OrderBy}
          />
          <PaginationFilter
            filterOptions={filterOptions}
            pagination={pagination}
          />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
        </div>

        <CardGridWrapper
          status={tickets.status}
          noData={tickets.data?.Data?.length === 0}
          cards={
            currentDisplay === 'card' ? (
              <React.Fragment>
                {tickets.data?.Data?.map(ticket => (
                  <TicketCard
                    key={ticket.Id}
                    ticket={ticket}
                    onRefetch={refetch}
                  />
                ))}
              </React.Fragment>
            ) : (
              <DataDisplayTable
                columns={ticketsTableColumns}
                dataSource={tickets.data?.Data}
              />
            )
          }
        />

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pagination.PageIndex}
          onChange={pagination.onPageIndexChange}
          pageSize={tickets.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={tickets.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
}

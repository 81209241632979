import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getUserSelectList } from '@Store/User/action';
import { useEffect } from 'react';

export function useUserSelectList() {
  const selectList = useAppSelector(state => state.User.selectList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserSelectList());
  }, []);

  return selectList;
}

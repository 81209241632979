import { Button, Card, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SortByFilter from './Components/SortByFilter';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import CardGridWrapper from '@Components/CardGridWrapper';
import { getAllUsers } from '@Store/User/action';
import UserCard from '@Cards/UserCard';
import { UserAddOutlined } from '@ant-design/icons';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import DataDisplayTable from '@Components/DataDisplayTable';
import useDataDisplay from '@Hooks/useDataDisplay';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import useUsersColumns from './useUsersColumns';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import './style.scss';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { FilterOption } from '@Types/Pagination';
import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';
import { getAllRoles } from '@Store/Role/action';
import Status from '@Enums/Status';

const AllUsers = () => {
  const [currentDisplay, toggleDisplay] = useDataDisplay('users');
  const users = useAppSelector(state => state.User.allUsers);
  const allRoles = useAppSelector(state => state.Role.allRoles);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  const filterOptions: FilterOption[] = [
    { field: 'Id', label: 'ID', type: FilterType.TEXT },
    { field: 'Name', label: t('name'), type: FilterType.TEXT },
    { field: 'Email', label: 'E-mail', type: FilterType.TEXT },
    {
      field: 'Stores.Store.BrandId',
      label: t('brand') + ' ID',
      type: FilterType.NUMBER,
    },
    {
      field: 'Stores.Store.Brand.Name',
      label: t('brand'),
      type: FilterType.TEXT,
    },
    {
      field: 'Stores.StoreId',
      label: t('store') + ' ID',
      type: FilterType.NUMBER,
    },
    { field: 'Stores.Store.Name', label: t('store'), type: FilterType.TEXT },
    {
      field: 'Roles.RoleId',
      label: t('role'),
      type: FilterType.NUMBER_ARRAY_SELECT,
      loading: allRoles?.status === Status.pending,
      options: allRoles?.data?.map(u => ({
        label: u.RoleName,
        value: u.RoleId,
      })),
    },
  ];

  const pageConfigResult = useUserPageConfig(
    'users',
    {
      orderBy: 'asc',
      sortKey: 'Name',
    },
    { fields: filterOptions.map(o => o.field) }
  );

  const { pagination, refetch } = usePaginatedQuery({
    status: users.status,
    filterOptions,
    pageConfigResult,
    queryFunction: p => dispatch(getAllUsers(p)),
  });

  const usersActionPerm = useCheckPermission(Permissions.ADMIN_USERS_ACTION);

  useSetHeaderTitle(t('allUsers'));

  const usersTableColumns = useUsersColumns({ users, getUsers: refetch });

  return (
    <Card>
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter
            onChange={pagination.onSortByChange}
            defaultValue={pagination.SortBy}
          />
          <PaginationOrderByFilter
            onChange={pagination.onOrderByChange}
            defaultValue={pagination.OrderBy}
          />
          <PaginationFilter
            filterOptions={filterOptions}
            pagination={pagination}
          />
          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          <Link to="new-user">
            <Button
              type="primary"
              icon={<UserAddOutlined />}
              disabled={!usersActionPerm}
            >
              {t('addUser')}
            </Button>
          </Link>
        </div>

        <div className="card-grid-container user-page-cards-container">
          <CardGridWrapper
            status={users.status}
            noData={users.data?.Data?.length === 0}
            cards={
              currentDisplay === 'card' ? (
                <React.Fragment>
                  {users.data?.Data?.map(user => (
                    <UserCard key={user.Id} user={user} getUsers={refetch} />
                  ))}
                </React.Fragment>
              ) : (
                <DataDisplayTable
                  columns={usersTableColumns}
                  dataSource={users.data?.Data}
                />
              )
            }
          />
        </div>

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pagination.PageIndex}
          onChange={pagination.onPageIndexChange}
          pageSize={users.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={users.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
};

export default AllUsers;

import './style.scss';
import { OverlayView } from '@react-google-maps/api';
import { Button, Card, CardProps, List } from 'antd';
import { MarkerLabel } from '@Features/homepage/homepage.types';
import { useRef } from 'react';
import { BoardCountInfo } from '@Components/BoardCountInfo';
import { useTranslation } from 'react-i18next';
import { useOutsideClick } from '@Hooks/useOutsideClick';

interface MarkerInfoProps extends CardProps {
  map?: google.maps.Map;
  locationMarker: MarkerLabel;
  onOutsideClick: () => void;
}

export function MarkerInfo(props: MarkerInfoProps) {
  const { map, locationMarker, onOutsideClick, ...restProps } = props;

  const { t } = useTranslation();

  const ref = useRef(null);

  useOutsideClick(ref, onOutsideClick);

  return (
    <OverlayView
      position={locationMarker.position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <Card
        ref={ref}
        className="cluster-info"
        bodyStyle={{ padding: 4 }}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        {...restProps}
      >
        <List
          header={
            <Button
              type="primary"
              block
              onClick={() => {
                map?.setZoom(21);
                map?.setOptions({ center: locationMarker.position });
              }}
            >
              {t('zoomAllTheWay')}
            </Button>
          }
          dataSource={[locationMarker]}
          renderItem={item => (
            <List.Item>
              <div className="cluster-row">
                <p className="cluster-label">{item.name + ':'}</p>
                <BoardCountInfo {...item} />
              </div>
            </List.Item>
          )}
        />
      </Card>
    </OverlayView>
  );
}

export enum AuditLogType {
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
}

export enum AuditLog {
  DELETE,
  UPDATE,
  CREATE,
}

import { Permissions } from '@Enums/Permission';
import { Category, Product, SortedPermissions } from '@Types/Permission';

export const permissionsLayout: SortedPermissions = [
  {
    name: 'PANEL',
    contentType: 'product',
    content: [
      {
        name: 'analytic',
        categories: [
          {
            name: 'brand',
            permissions: [
              Permissions.PANEL_PAGE_COMPANY,
              Permissions.PANEL_PAGE_COMPANY_IN_STORE,
              Permissions.PANEL_PAGE_COMPANY_CATEGORY,
            ],
          },
          {
            name: 'store',
            permissions: [Permissions.PANEL_PAGE_LOCATION],
          },
          {
            name: 'densityMap',
            permissions: [Permissions.PANEL_PAGE_AREA],
          },
          {
            name: 'performanceTable',
            permissions: [Permissions.PANEL_PAGE_PERFORMANCE_TABLE],
          },
          {
            name: 'department',
            permissions: [
              Permissions.PANEL_PAGE_DEPARTMENT,
              Permissions.PANEL_PAGE_SHELF,
            ],
          },
          {
            name: 'trends',
            permissions: [Permissions.PANEL_PAGE_TRENDS],
          },
          {
            name: 'suggestions',
            permissions: [
              Permissions.PANEL_PAGE_SUGGESTION,
              Permissions.PANEL_PAGE_SUGGESTION_DETAIL,
            ],
          },
          {
            name: 'checkout',
            permissions: [Permissions.PANEL_PAGE_CHECKOUT],
          },
          {
            name: 'reports.reports',
            permissions: [Permissions.PANEL_PAGE_REPORT],
          },
          {
            name: 'camera',
            permissions: [
              Permissions.PANEL_PAGE_CAMERAS,
              Permissions.PANEL_PAGE_CAMERAS_LIVE_AREAS,
              Permissions.PANEL_PAGE_CAMERAS_DETAIL,
            ],
          },
          {
            name: 'storeComparison',
            permissions: [Permissions.PANEL_PAGE_LOCATION_COMPARISON],
          },
          {
            name: 'categoryComparison',
            permissions: [Permissions.PANEL_PAGE_CATEGORY_COMPARISON],
          },
          {
            name: 'campaigns',
            permissions: [Permissions.PANEL_PAGE_CAMPAIGNS],
          },
          {
            name: 'campaignAnalytic',
            permissions: [Permissions.PANEL_PAGE_CAMPAIGN_ANALYSIS],
          },
          {
            name: 'settings',
            permissions: [
              Permissions.PANEL_PAGE_SETTINGS,
              Permissions.PANEL_PAGE_SETTINGS_CAMERA,
              Permissions.PANEL_PAGE_SETTINGS_NOTIFICATIONS,
              Permissions.PANEL_PAGE_SETTINGS_DEPARTMENT,
              Permissions.PANEL_PAGE_SETTINGS_WORKING_HOURS,
              Permissions.PANEL_PAGE_SETTINGS_SALES_UPLOADING,
              Permissions.PANEL_PAGE_SETTINGS_CAMERA_SNAPSHOT,
              Permissions.PANEL_PAGE_SETTINGS_FORECAST,
              Permissions.PANEL_PAGE_SETTINGS_CAMPAIGN,
              Permissions.PANEL_PAGE_SETTINGS_IFRAME,
              Permissions.PANEL_PAGE_SETTINGS_UPLOAD_REPORTS,
              Permissions.PANEL_PAGE_SETTINGS_SKETCH,
              Permissions.PANEL_PAGE_SETTINGS_REPORTS,
            ],
            disabledPermissions: [Permissions.PANEL_PAGE_SETTINGS],
          },
          {
            name: 'tickets',
            permissions: [Permissions.PANEL_PAGE_TICKET_VIEW],
          },
          {
            name: 'others',
            permissions: [
              Permissions.PANEL_PAGE_SALES_CONFIGURATION,
              Permissions.PANEL_PAGE_USER,
              Permissions.PANEL_PAGE_NOTIFICATIONS,
              Permissions.PANEL_PAGE_IFRAME_VIEW,
            ],
          },
        ],
      },
      {
        name: 'ageGender',
        categories: [
          {
            name: 'brand',
            permissions: [
              Permissions.PANEL_PAGE_COMPANY,
              Permissions.PANEL_PAGE_COMPANY_IN_STORE,
              Permissions.PANEL_PAGE_COMPANY_GENDER_AGE,
            ],
          },
          {
            name: 'store',
            permissions: [Permissions.PANEL_PAGE_LOCATION],
          },
          {
            name: 'ageGender',
            permissions: [
              Permissions.PANEL_PAGE_GENDER_AGE_COMPANY,
              Permissions.PANEL_PAGE_CUSTOMER_GENDER_AGE,
              Permissions.PANEL_PAGE_DEPARTMENT_AGE_GENDER,
            ],
          },
          {
            name: 'ageGenderComparison',
            permissions: [Permissions.PANEL_PAGE_AGE_GENDER_COMPARISON],
          },
          {
            name: 'others',
            permissions: [
              Permissions.PANEL_PAGE_USER,
              Permissions.PANEL_PAGE_NOTIFICATIONS,
              Permissions.PANEL_PAGE_IFRAME_VIEW,
            ],
          },
        ],
      },
      {
        name: 'peopleCounting',
        categories: [
          {
            name: 'brand',
            permissions: [
              Permissions.PANEL_PAGE_COMPANY,
              Permissions.PANEL_PAGE_COMPANY_IN_STORE,
              Permissions.PANEL_PAGE_STORE_COUNT,
            ],
          },
          {
            name: 'store',
            permissions: [Permissions.PANEL_PAGE_LOCATION],
          },
          {
            name: 'cameras',
            permissions: [
              Permissions.PANEL_PAGE_CAMERAS,
              Permissions.PANEL_PAGE_CAMERAS_LIVE_AREAS,
              Permissions.PANEL_PAGE_CAMERAS_DETAIL,
            ],
          },
          {
            name: 'others',
            permissions: [
              Permissions.PANEL_PAGE_LIVE,
              Permissions.PANEL_PAGE_LIVE_CUSTOMER_COUNT,
              Permissions.PANEL_PAGE_USER,
              Permissions.PANEL_PAGE_NOTIFICATIONS,
              Permissions.PANEL_PAGE_IFRAME_VIEW,
            ],
          },
        ],
      },
      {
        name: 'personnel',
        categories: [
          {
            name: 'personnel',
            permissions: [
              Permissions.PANEL_PAGE_PERSONNEL_PERSONNEL,
              Permissions.PANEL_PAGE_RECEIVER,
              Permissions.PANEL_PAGE_PERSONNEL,
            ],
            disabledPermissions: [Permissions.PANEL_PAGE_PERSONNEL],
          },
          {
            name: 'settings',
            permissions: [
              Permissions.PANEL_PAGE_SETTINGS_CAMERA,
              Permissions.PANEL_PAGE_SETTINGS_SKETCH,
            ],
          },
          {
            name: 'others',
            permissions: [
              Permissions.PANEL_PAGE_USER,
              Permissions.PANEL_PAGE_NOTIFICATIONS,
              Permissions.PANEL_PAGE_IFRAME_VIEW,
            ],
          },
        ],
      },
    ] as Product[],
  },

  {
    name: 'ADMIN',
    contentType: 'category',
    content: [
      {
        name: 'camera',
        permissions: [
          Permissions.ADMIN_CAMERA_VIEW,
          Permissions.ADMIN_CAMERA_GET_PHOTO,
          Permissions.ADMIN_CAMERA_TAKE_PHOTO,
          Permissions.ADMIN_CAMERA_SEND_PING,
          Permissions.ADMIN_CAMERA_OPEN_SSH,
          Permissions.ADMIN_CAMERA_ADD,
          Permissions.ADMIN_CAMERA_EDIT,
          Permissions.ADMIN_CAMERA_RECORDS_VIEW,
          Permissions.ADMIN_CAMERA_CALIBRATION_VIEW,
          Permissions.ADMIN_CAMERA_TAKE_PHOTO_FROM_ALL,
          Permissions.ADMIN_CAMERA_ARCHIVE,
          Permissions.ADMIN_CAMERA_ACTION,
          Permissions.ADMIN_CAMERA_RECORDS_ACTION,
          Permissions.ADMIN_CAMERA_RULES_VIEW,
          Permissions.ADMIN_CAMERA_RULES_ACTION,
        ],
      },
      {
        name: 'brand',
        permissions: [
          Permissions.ADMIN_BRAND_VIEW,
          Permissions.ADMIN_BRAND_ACTION,
          Permissions.ADMIN_BRAND_EMAILS_VIEW,
        ],
      },
      {
        name: 'store',
        permissions: [
          Permissions.ADMIN_STORE_VIEW,
          Permissions.ADMIN_STORE_ACTION,
          Permissions.ADMIN_STORE_CHECKIN_VIEW,
        ],
      },
      {
        name: 'board',
        permissions: [
          Permissions.ADMIN_BOARD_VIEW,
          Permissions.ADMIN_BOARD_ACTION,
        ],
      },
      {
        name: 'user',
        permissions: [
          Permissions.ADMIN_USERS_VIEW,
          Permissions.ADMIN_USERS_ACTION,
        ],
      },
      {
        name: 'beacon',
        permissions: [
          Permissions.ADMIN_BEACON_ACTION,
          Permissions.ADMIN_BEACON_VIEW,
        ],
      },
      {
        name: 'gateway',
        permissions: [
          Permissions.ADMIN_GATEWAY_ACTION,
          Permissions.ADMIN_GATEWAY_VIEW,
        ],
      },
      {
        name: 'receiver',
        permissions: [
          Permissions.ADMIN_RECEIVER_ACTION,
          Permissions.ADMIN_RECEIVER_VIEW,
        ],
      },
      {
        name: 'custom-roles',
        permissions: [
          Permissions.ADMIN_CUSTOM_ROLE_VIEW,
          Permissions.ADMIN_CUSTOM_ROLE_ACTION,
        ],
      },
      {
        name: 'beacon-rules',
        permissions: [
          Permissions.ADMIN_BEACON_RULES_ACTION,
          Permissions.ADMIN_BEACON_RULES_VIEW,
        ],
      },
      {
        name: 'tickets',
        permissions: [
          Permissions.ADMIN_TICKET_ACTION,
          Permissions.ADMIN_TICKET_VIEW,
        ],
      },
      {
        name: 'others',
        permissions: [
          Permissions.ADMIN_PERSONELS_VIEW,
          Permissions.ADMIN_COORDINATES_VIEW,
          Permissions.ADMIN_SUMMARY_VIEW,
          Permissions.ADMIN_CARD_LIVE_VIEW,
        ],
      },
    ] as Category[],
  },

  {
    name: 'LABELING',
    contentType: 'permission',
    content: [
      Permissions.LABELING_PAGE_PHOTO_LABELING,
      Permissions.LABELING_PAGE_VALIDATION,
      Permissions.LABELING_PAGE_VIDEO_CAPTURES,
    ] as Permissions[],
    onlyVisibleToAdmins: true,
  },

  {
    name: 'DIAGNIFY',
    contentType: 'permission',
    content: [
      Permissions.DIAGNIFY_APPLICATION,
      Permissions.DIAGNIFY_PROCESS_WITHOUTCHECKIN,
    ] as Permissions[],
  },

  {
    name: 'OTHERS',
    contentType: 'permission',
    content: [] as Permissions[],
  },
];

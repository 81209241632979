import { Button, Card, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import usePaginate from '@Hooks/usePaginate';
import * as FilterTypes from '@Types/Filter';
import SortByFilter from '../SortByFilter';
import OrderByFilter from '@Components/OrderByFilter';
import CustomFilter from '../CustomFilter';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import CardGridWrapper from '@Components/CardGridWrapper';
import ArchivedFilter from '../../../../Components/ArchivedFilter';
import GoBackButton from '@Components/GoBackButton';
import { PlusCircleOutlined } from '@ant-design/icons';
import { GetCamerasParams } from '@Types/Camera';
import CameraCard from '@Cards/CameraCard';
import './style.scss';
import PermissionCheck from '@HOC/PermissionCheck';
import { Permissions } from '@Enums/Permission';
import useCheckPermission from '@Hooks/useCheckPermission';
import useDataDisplay from '@Hooks/useDataDisplay';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import DataDisplayTable from '@Components/DataDisplayTable';
import useCamerasColumns from './useCamerasColumns';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { getAllLensType } from '@Store/LensType/action';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { getCodeVersions } from '@Store/CodeVersion/action';
import {
  defaultPaginationParams,
  noPaginationParams,
} from '@Hooks/usePagination.ts/constants';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';
import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import { FilterOption } from '@Types/Pagination';

type Props = {
  getUnarchivedCamerasAction: Function;
  getArchivedCamerasAction: Function;
  title: string;
};

const CamerasDetail = ({
  getUnarchivedCamerasAction,
  getArchivedCamerasAction,
  title,
}: Props) => {
  const location = useLocation();
  const [showArchivedCameras, setShowArchivedCameras] = useState<boolean>(
    location.state?.showArchived
  );
  const [currentDisplay, toggleDisplay] = useDataDisplay('cameras');
  const fetchedCameras = useAppSelector(s => s.Camera.fetchedCameras);
  const codeVersions = useAppSelector(s => s.CodeVersion.codeVersions);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { boardId, storeId } = useParams();

  useEffect(() => {
    dispatch(getAllLensType(noPaginationParams));
    dispatch(getCodeVersions());
  }, []);

  useSetHeaderTitle(
    `${title} - ${
      showArchivedCameras ? t('archivedCameras') : t('unarchivedCameras')
    }`
  );

  const filterOptions: FilterOption[] = [
    { field: 'Id', label: 'ID', type: FilterType.NUMBER },
    { field: 'Name', label: t('name'), type: FilterType.TEXT },
    {
      field: 'Board.CodeVersionId',
      label: t('codeVersion'),
      type: FilterType.NUMBER_ARRAY_SELECT,
      options: codeVersions?.data?.map(u => ({
        label: u.Name,
        value: u.Id,
      })),
    },
    {
      field: 'Board.Passive',
      label: t('status'),
      type: FilterType.BOOLEAN,
      radioOptions: [
        { label: t('off'), value: true },
        { label: t('on'), value: false },
        { label: t('all'), value: undefined },
      ],
    },
  ];

  const pageConfigResult = useUserPageConfig(
    'cameras',
    {
      orderBy: 'asc',
      sortKey: 'Name',
    },

    { fields: filterOptions.map(o => o.field) }
  );

  const { pagination, refetch } = usePaginatedQuery({
    status: fetchedCameras.status,
    filterOptions,
    pageConfigResult,
    refetchDependencies: [showArchivedCameras],
    queryFunction: p => getCameras(p),
  });

  const cameraActionPerm = useCheckPermission(Permissions.ADMIN_CAMERA_ACTION);

  const toggleShowArchievedCameras = () => {
    setShowArchivedCameras(p => !p);
  };

  const getCameras = (filters: FilterTypes.PaginationParams) => {
    const params: GetCamerasParams = {
      filters,
      boardId: parseInt(boardId!),
      storeId: parseInt(storeId!),
    };

    if (showArchivedCameras) {
      dispatch(getArchivedCamerasAction(params));
    } else {
      dispatch(getUnarchivedCamerasAction(params));
    }
  };

  const camerasTableColumns = useCamerasColumns({
    cameras: fetchedCameras,
    isArchived: showArchivedCameras,
    getCameras: refetch,
  });

  return (
    <Card className="camera-detail-container">
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <GoBackButton />
          <SortByFilter
            onChange={pagination.onSortByChange}
            defaultValue={pagination.SortBy}
          />
          <PaginationOrderByFilter
            onChange={pagination.onOrderByChange}
            defaultValue={pagination.OrderBy}
          />
          <PaginationFilter
            filterOptions={filterOptions}
            pagination={pagination}
          />

          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          <ArchivedFilter
            onClick={toggleShowArchievedCameras}
            showArchived={showArchivedCameras}
          />
          {boardId && (
            <PermissionCheck permission={Permissions.ADMIN_CAMERA_ADD}>
              <>
                <Link to="new-camera">
                  <Button
                    type="primary"
                    className="new-camera-btn"
                    icon={<PlusCircleOutlined />}
                    disabled={!cameraActionPerm}
                  >
                    {t('addCamera')}
                  </Button>
                </Link>
              </>
            </PermissionCheck>
          )}
        </div>

        <CardGridWrapper
          status={fetchedCameras.status}
          error={fetchedCameras.ErrorMessage}
          noData={fetchedCameras.data?.Data?.length === 0}
          cards={
            currentDisplay === 'card' ? (
              <React.Fragment>
                {fetchedCameras.data?.Data?.map(c => (
                  <CameraCard
                    camera={c}
                    key={c.Id}
                    isArchived={showArchivedCameras}
                    getCameras={refetch}
                  />
                ))}
              </React.Fragment>
            ) : (
              <DataDisplayTable
                columns={camerasTableColumns}
                dataSource={fetchedCameras.data?.Data}
              />
            )
          }
        />

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pagination.PageIndex}
          onChange={pagination.onPageIndexChange}
          pageSize={fetchedCameras.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={fetchedCameras.data?.TotalNumberOfRecords}
        />
      </div>

      <GoBackButton />
    </Card>
  );
};

export default CamerasDetail;

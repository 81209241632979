import Status from '@Enums/Status';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import FilledText from '@Components/FilledText';
import { AllVersion, AllVersions } from '@Types/Version';
import VersionActionButtons from '@Cards/VersionCard/VersionActionButtons';
import useIsEnvAdmin from '@Hooks/useIsEnvAdmin';
import { Link } from 'react-router-dom';
import { RowProps, Tooltip } from 'antd';

const filledTextRowProps: RowProps = {
  justify: 'center',
};

interface Params {
  versions: AllVersions;
  getVersions: () => void;
}

/**
 * @description This "hook" is not a generic hook. It's made to abstract the code and make it much more readable. It will only be used for <AllVersions/>.
 */
export default function useVersionsColumns(
  props: Params
): ColumnsType<AllVersion> {
  const { versions } = props;

  const isEnvAdmin = useIsEnvAdmin();

  const { t } = useTranslation();

  const versionsTableColumns: ColumnsType<AllVersion> = useMemo(() => {
    if (versions.status !== Status.success) return [];

    const columns: ColumnsType<AllVersion> = [
      {
        title: t('ID'),
        dataIndex: 'Id',
        align: 'center',
      },
      {
        title: t('name'),
        dataIndex: 'Name',
        align: 'center',
      },
      {
        title: t('version'),
        dataIndex: 'Version',
        align: 'center',
      },
      {
        title: t('imageVersion'),
        dataIndex: 'ImageVersionStr',
        align: 'center',
      },
      {
        title: t('Main'),
        dataIndex: 'Main',
        align: 'center',
        render: (_, item) => (
          <Tooltip title={item.CompMain?.ActiveFile?.Name}>
            <FilledText
              rowProps={filledTextRowProps}
              text={item?.CompMain?.Name ?? item?.Main?.Name}
            />
          </Tooltip>
        ),
      },
      {
        title: t('Model'),
        dataIndex: 'Model',
        align: 'center',
        render: (_, item) => (
          <Tooltip title={item.CompModel?.ActiveFile?.Name}>
            <FilledText
              rowProps={filledTextRowProps}
              text={item?.CompModel?.Name ?? item?.Model?.Name}
            />
          </Tooltip>
        ),
      },
      {
        title: t('control'),
        dataIndex: 'Control',
        align: 'center',
        render: (_, item) => (
          <Tooltip title={item.CompControl?.ActiveFile?.Name}>
            <FilledText
              rowProps={filledTextRowProps}
              text={item?.CompControl?.Name ?? item?.Control?.Name}
            />
          </Tooltip>
        ),
      },
      {
        title: t('navigation.beacons'),
        dataIndex: 'Beacon',
        align: 'center',
        render: (_, item) => (
          <Tooltip title={item.CompBeacon?.ActiveFile?.Name}>
            <FilledText
              rowProps={filledTextRowProps}
              text={item?.CompBeacon?.Name ?? item?.Beacon?.Name}
            />
          </Tooltip>
        ),
      },
      {
        title: t('hardware'),
        dataIndex: 'Hardware',
        align: 'center',
        render: (hardware: string) => (
          <FilledText rowProps={filledTextRowProps} text={hardware ?? '-'} />
        ),
      },
      {
        title: t('versionsPage.boardCount'),
        dataIndex: 'BoardCount',
        align: 'center',
        render: (_, version) => (
          <Link
            to={`/all-boards?filter=[{"field":"CodeVersionId","value":[${version.Id}]}]&sortBy=CodeVersionId&orderBy=ASC`}
          >
            {version.BoardCount}
          </Link>
        ),
      },
    ];

    if (isEnvAdmin) {
      columns.push({
        title: t('actionButtons'),
        dataIndex: 'actionButtons',
        render: (_: any, version: AllVersion) => (
          <VersionActionButtons
            getVersions={props.getVersions}
            version={version}
          />
        ),
      });
    }

    return columns;
  }, [versions.status]);

  return versionsTableColumns;
}
